import React from 'react';
import { Form, Input, Row, Col, FormGroup, Label } from 'reactstrap';
import { useIntl } from 'react-intl';
import { Button_ } from './ActionButtons/ActionButtons';
import { icons } from '../Icons/icons';
import { SelectInput } from './Selects';
import NumberFormat from 'react-number-format';
import { useFormik } from 'formik';
import swal from 'sweetalert2';
import axios from 'axios';
import { arc } from '../pages/Summarys/utils';
import { Alerts } from '../Components/Alerts';
import { categories, stateOptions } from '../Util/StartupScoring';
import { FormattedMessage } from 'react-intl';

const API = 'https://external.jetscoring.com/startup/score';

const formatData = (data) => {
  const formattedData = {};
  Object.entries(data).forEach(([key, value]) => {
    if (['C', 'H', 'KS', 'Y_C'].includes(key)) {
      if (key === 'H')
        formattedData[key] =
          value?.value === 'Diğer'
            ? `Gaziantep / Turkey`
            : `${value?.value} / Turkey`;
      else formattedData[key] = value?.value;
    } else if (key === 'KOT') formattedData[key] = value > 49 ? 49 : value;
    else if (key === 'KOST') formattedData[key] = value > 19 ? 19 : value;
    else formattedData[key] = value;
  });
  return formattedData;
};

const formatDataSubmit = (data) => {
  const jsonObject = JSON.stringify(formatData(data));
  return jsonObject;
};

const postData = {
  customer_name: '1507',
  model_name: '1507_ver1',
};

const ksOptions = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: <FormattedMessage id="5_AND_MORE" />,
  },
];

const hOptions = stateOptions.map((city) => ({
  value: city,
  label: city,
}));

const year = new Date().getFullYear();

const ycOptions = Array.from({ length: 41 }, (_, i) => ({
  value: year - i,
  label: year - i,
}));

// const getInput = (props) => {
//   const { type, name, value, options, id } = props;
//   switch (type) {
//     case 'select':
//       return (
//         <SelectInput name={name} value={value} options={options} id={id} />
//       );
//     case 'radio-button':
//       return <Input id={id} type="checkbox" name={name} value={value} />;
//     case 'number':
//       return 'number';
//     case 'text':
//       return 'number';
//     default:
//       return 'text';
//   }
// };

const defaultState = {
  KS: null,
  TKD: 'Evet',
  KM: 'Teknik',
  KAT: 'Evet',
  KOT: null,
  KOST: null,
  KYDT: 'Evet',
  KYLDT: 'Evet',
  H: null,
  Y_C: null,
  C: null,
  F: null,
};

export const StartupScoringForm = () => {
  const intl = useIntl();

  const [data, setData] = React.useState(null);

  const formik = useFormik({
    initialValues: {
      ...defaultState,
    },
    validateOnChange: true,
    validate: (data) => {
      const errors = {};
      Object.entries(data).some(([key, value]) => {
        if (value === null) errors['required'] = true;
      });

      return errors;
    },

    onSubmit: async (data, { resetForm }) => {
      const data_ = {
        ...postData,
        input_map: formatDataSubmit(data),
      };

      try {
        const response = await axios.post(API, data_, {});
        if (response && response.status === 200) {
          setData(response.data);
        } else {
          setData(null);
          swal.fire({
            icon: 'warning',
            title: 'Sonuç bulunamadı.',
            showConfirmButton: false,
            width: '300px',
          });
        }
      } catch (e) {
        setData(null);
        swal.fire({
          icon: 'error',
          title: 'Bir Hata Meydana Geldi.',
          showConfirmButton: false,
          width: '300px',
        });
      } finally {
        console.log('finally');
      }
    },
  });

  const handleChangeValue = (field, value) => {
    formik.setFieldValue(field, value);
  };

  console.log('values: ', formik.values);

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col>
            <FormGroup>
              <Label>
                <FormattedMessage id="FOUNDER_GRADUATION_STATUS" />
              </Label>
              <Row>
                <Col>
                  <Label className="mr-2" htmlFor="KM1">
                    <FormattedMessage id="YES" />
                  </Label>

                  <Input
                    name="KM"
                    type="checkbox"
                    value={formik.values.KM}
                    checked={formik.values.KM === 'Teknik'}
                    id="KM1"
                    onChange={() => handleChangeValue('KM', 'Teknik')}
                  />
                </Col>
                <Col>
                  <Label className="mr-2" htmlFor="KM2">
                    <FormattedMessage id="NO" />
                  </Label>
                  <Input
                    name="KM"
                    type="checkbox"
                    value={formik.values.KM}
                    checked={formik.values.KM === 'NonTeknik '}
                    id="KM2"
                    onChange={() => handleChangeValue('KM', 'NonTeknik ')}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>
                <FormattedMessage id="IS_THERE_AN_ENGINEER_AMONG_THE_FOUNDERS" />
              </Label>
              <Row>
                <Col>
                  <Label className="mr-2" htmlFor="TKD1">
                    <FormattedMessage id="YES" />
                  </Label>

                  <Input
                    name="TKD"
                    type="checkbox"
                    value={formik.values.TKD}
                    checked={formik.values.TKD === 'Evet'}
                    id="TKD1"
                    onChange={() => handleChangeValue('TKD', 'Evet')}
                  />
                </Col>
                <Col>
                  <Label className="mr-2" htmlFor="TKD2">
                    <FormattedMessage id="NO" />
                  </Label>
                  <Input
                    name="KD"
                    type="checkbox"
                    value={formik.values.TKD}
                    checked={formik.values.TKD === 'Hayır'}
                    id="TKD2"
                    onChange={() => handleChangeValue('TKD', 'Hayır')}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>
                <FormattedMessage id="IS_THERE_A_FOUNDER_WITH_A_MASTER_S_OR_PHD_DEGREE" />{' '}
              </Label>
              <Row>
                <Col>
                  <Label className="mr-2" htmlFor="KYLDT1">
                    <FormattedMessage id="YES" />
                  </Label>
                  <Input
                    name="KYLDT"
                    type="checkbox"
                    value={formik.values.KYLDT}
                    checked={formik.values.KYLDT === 'Evet'}
                    id="KYLDT1"
                    onChange={() => handleChangeValue('KYLDT', 'Evet')}
                  />
                </Col>
                <Col>
                  <Label className="mr-2" htmlFor="KYLDT2">
                    <FormattedMessage id="NO" />
                  </Label>
                  <Input
                    name="KYLDT"
                    type="checkbox"
                    value={formik.values.KYLDT}
                    checked={formik.values.KYLDT === 'Hayır'}
                    id="KYLDT2"
                    onChange={() => handleChangeValue('KYLDT', 'Hayır')}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>
                <FormattedMessage id="DOES_THE_FOUNDER_HAVE_PREVIOUS_EXPERIENCE_IN_THE_STARTUPS_BUSINESS_FIELD" />
              </Label>
              <Row>
                <Col>
                  <Label className="mr-2" htmlFor="KAT1">
                    <FormattedMessage id="YES" />
                  </Label>

                  <Input
                    name="KAT"
                    type="checkbox"
                    value={formik.values.KAT}
                    checked={formik.values.KAT === 'Evet'}
                    id="KAT1"
                    onChange={() => handleChangeValue('KAT', 'Evet')}
                  />
                </Col>
                <Col>
                  <Label className="mr-2" htmlFor="KAT2">
                    {' '}
                    <FormattedMessage id="NO" />
                  </Label>

                  <Input
                    name="KAT"
                    type="checkbox"
                    value={formik.values.KAT}
                    checked={formik.values.KAT === 'Hayır'}
                    onChange={() => handleChangeValue('KAT', 'Hayır')}
                    id="KAT2"
                  />
                </Col>
              </Row>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>
                <FormattedMessage id="IS_THERE_A_CO_FOUNDER_WITH_INTERNATIONAL_WORK_EXPERIENCE" />
              </Label>
              <Row>
                <Col>
                  <Label className="mr-2" htmlFor="KYDT1">
                    {' '}
                    <FormattedMessage id="YES" />
                  </Label>
                  <Input
                    name="KYDT"
                    type="checkbox"
                    value={formik.values.KYDT}
                    checked={formik.values.KYDT === 'Evet'}
                    id="KYDT1"
                    onChange={() => handleChangeValue('KYDT', 'Evet')}
                  />
                </Col>
                <Col>
                  <Label className="mr-2" htmlFor="KYDT2">
                    {' '}
                    <FormattedMessage id="NO" />
                  </Label>
                  <Input
                    name="KYDT"
                    type="checkbox"
                    value={formik.values.KYDT}
                    checked={formik.values.KYDT === 'Hayır'}
                    id="KYDT2"
                    onChange={() => handleChangeValue('KYDT', 'Hayır')}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>
                <FormattedMessage id="CURRENT_NUMBER_OF_FOUNDERS" />
              </Label>
              <SelectInput
                value={formik.values.KS}
                options={ksOptions}
                onChange={(e) => handleChangeValue('KS', e || null)}
                isMulti={false}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>
                <FormattedMessage id="YEAR_OF_COMPANY_ESTABLISHMENT" />
              </Label>
              <SelectInput
                value={formik.values.Y_C}
                name="Y_C"
                options={ycOptions}
                onChange={(e) => handleChangeValue('Y_C', e || null)}
                isMulti={false}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>
                <FormattedMessage id="COMPANY_HEADQUARTERS" />{' '}
              </Label>
              <SelectInput
                value={formik.values.H}
                options={hOptions}
                onChange={(e) => handleChangeValue('H', e || null)}
                isMulti={false}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>
                {' '}
                <FormattedMessage id="INDUSTRY_OF_THE_COMPANY" />
              </Label>

              <SelectInput
                value={formik.values.C}
                name="C"
                options={categories.map((e) => {
                  return {
                    value: e.enName,
                    label:
                      localStorage.frayjet_frayjet_language === 'en'
                        ? e.enName
                        : e.trName,
                  };
                })}
                onChange={(e) => handleChangeValue('C', e || null)}
                isMulti={false}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>
                {' '}
                <FormattedMessage id="THE_TOTAL_INVESTMENT_AMOUNT_RECEIVED_BY_THE_COMPANY" />
              </Label>

              <NumberFormat
                name="F"
                value={formik.values.F ?? ''}
                className="form-control"
                allowNegative={false}
                thousandSeparator={'.'}
                decimalSeparator={','}
                onValueChange={(values) => {
                  const { floatValue } = values;
                  handleChangeValue(
                    'F',
                    floatValue !== undefined ? floatValue : null
                  );
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>
                <FormattedMessage id="AVERAGE_EXPERIENCE_DURATION_OF_THE_FOUNDER" />
              </Label>
              <NumberFormat
                name="KOT"
                value={formik.values.KOT ?? ''}
                className="form-control"
                allowNegative={false}
                thousandSeparator={'.'}
                decimalSeparator={','}
                onValueChange={(values) => {
                  const { floatValue } = values;
                  handleChangeValue(
                    'KOT',
                    floatValue !== undefined ? floatValue : null
                  );
                }}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>
                <FormattedMessage id="FOUNDERS_INITIAL_STARTUP_EXPERIENCE" />
              </Label>

              <NumberFormat
                name="KOST"
                value={formik.values.KOST ?? ''}
                className="form-control"
                allowNegative={false}
                thousandSeparator={'.'}
                decimalSeparator={','}
                onValueChange={(values) => {
                  const { floatValue } = values;
                  handleChangeValue(
                    'KOST',
                    floatValue !== undefined ? floatValue : null
                  );
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <div
            style={{
              display: 'flex',
              gap: '8px',
            }}
          >
            <Button_
              text={intl.formatMessage({
                id: 'SEND',
              })}
              color="primary"
              iconClassName={icons.save}
              disabled={formik.isSubmitting}
              loading={formik.isSubmitting}
              type="submit"
            />

            <Button_
              text={intl.formatMessage({
                id: 'RESET',
              })}
              color="danger"
              iconClassName={icons.delete}
              onClick={(e) => {
                formik.setValues(defaultState);
                setData(null);
              }}
              disabled={formik.isSubmitting}
            />
          </div>
        </Row>
        <Row>
          {formik.errors.required && (
            <Label
              style={{
                color: '#ff0000',
              }}
            >
              <FormattedMessage id="REQUIRED_FIELDS" />
            </Label>
          )}
        </Row>
      </Form>

      <Row className="mt-3">
        {formik.isSubmitting ? (
          <Alerts
            color="primary"
            className="fas fa-spin fa-spinner"
            messageId={'LOADING'}
            label=""
          />
        ) : (
          data && responseContent(data)
        )}
      </Row>
    </>
  );
};

const responseContent = (data) => {
  const { confidence, result } = data;
  const value =
    confidence >= 0.7 ? (result === 'Yes' ? 29 / 2 : 171 / 2) : 100 / 2;

  const cx = 100;
  const cy = 100;
  const ringThick = 15;
  const ringRadius = 100;

  const pointerAngle_1 = (value * 180) / 100;

  const ringSegments = [
    { color: 'var(--bs-success)', start: 0, end: 29, labelId: 'SUCCESSFUL' },
    {
      color: 'var(--bs-warning)',
      start: 31,
      end: 69,
      labelId: 'PARTIALLY_SUCCESSFUL',
    },
    {
      color: 'var(--bs-danger)',
      start: 71,
      end: 100,
      labelId: 'UNSUCCESSFUL',
    },
  ];

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <svg style={{ overflow: 'visible' }}>
          <g>
            {ringSegments.map(({ color, start, end, labelId }, i) => {
              return (
                <>
                  <path
                    key={i}
                    fill="none"
                    stroke={color}
                    strokeWidth={ringThick}
                    d={arc(
                      cx,
                      cy,
                      ringRadius - ringThick / 2,
                      (start * 180) / 100 - 90,
                      (end * 180) / 100 - 90
                    )}
                  />
                  <text
                    x={cx}
                    y={cy}
                    dy={-ringRadius + ringThick / 2 - 12}
                    textAnchor="middle"
                    dominantBaseline="auto"
                    transform={`rotate(${(((start + end) / 2) * 180) / 100 - 90}, ${cx}, ${cy})`}
                  >
                    <FormattedMessage id={labelId} />
                  </text>
                </>
              );
            })}
          </g>

          <g
            id="pointer-triangle"
            transform={`translate(${cx}, ${cy}), rotate(${pointerAngle_1})`}
          >
            <path
              d="M -8 0 L 8 0 L 0 60 z"
              fill="var(--bs-secondary)"
              stroke="none"
              transform={`translate(${
                -ringRadius + ringThick + 90 - 4
              } ), rotate(90)`}
            />
          </g>
        </svg>
      </div>
    </div>
  );
};
