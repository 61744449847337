import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import 'primereact/resources/themes/saga-blue/theme.css';
// import 'primereact/resources/primereact.min.css';
import { FormattedMessage } from 'react-intl';
import { FormattedDate } from 'react-intl';
import Swal from 'sweetalert2';
import { useIntl } from 'react-intl';
import {
  LinkButton,
  DeleteButton,
} from '../Components/ActionButtons/ActionButtons';
import { icons } from '../Icons/icons';
import { useAuth } from 'react-admin-base';
import { DownloadExcelFromUrlButton } from './ActionButtons/ActionButtons';
import { useState } from 'react';
import { InputField } from '../Components/Input';

const providerTypeNums = [1, 3, 7, 9, 10, 14, 15, 11, 13, 16];
const downloadUrl = '/analyze/get/all?sort=id&desc=true&itemPerPage=-1';

export const DataTable_ = (props) => {
  const intl = useIntl();
  const [api] = useAuth();

  const {
    code,
    hasDownloadButton,
    values,
    isButtonCkicked,
    hasDeleteButton,
    openNewTab,
    hasEditButton,
    hasCustomerPageButton,
  } = props;

  const [lazyParams, setLazyParams] = React.useState({
    first: 1,
    rows: 10,
    page: 1,
    sortField: 'id',
    sortOrder: 1,
    filters: {},
    multiSortMeta: [],
  });

  const [globalFilterValue, setGlobalFilterValue] = useState('');

  const [loading, setLoading] = React.useState(false);
  const [analysis, setAnalysis] = React.useState(null);
  const [newColumns, setNewColumns] = React.useState([]);

  const values_ = values
    ? values.filter((a) => {
        if (Array.isArray(a.value)) {
          return a.value.length > 0;
        } else if (typeof a.value === 'object') {
          return a.value.minValue || a.value.maxValue;
        }
      })
    : [];

  React.useEffect(() => {
    fetchData();
    setNewColumns(
      values_
        ? values_.map((v, i) => {
            const { name, messageId, section, formatter } = v;

            if (messageId) {
              const header_ = Array.isArray(messageId)
                ? `${intl.formatMessage({ id: messageId[0] })} /
            ${intl.formatMessage({ id: messageId[1] })}`
                : intl.formatMessage({ id: messageId });
              return (
                <Column
                  key={i}
                  field={name}
                  header={header_}
                  body={(rowData) => {
                    if (
                      section === 'financial-ratios' ||
                      section === 'financial-items'
                    ) {
                      const values = rowData[name];
                      if (!values || values.length === 0) return null;
                      return (
                        <table
                          style={{
                            border: '1px solid #ccc',
                            borderCollapse: 'collapse',
                          }}
                        >
                          <thead>
                            <tr>
                              <th
                                style={{
                                  border: '1px solid #ccc',
                                  borderCollapse: 'collapse',
                                  padding: '0 0.5rem',
                                }}
                              >
                                <FormattedMessage id="DATE" />
                              </th>
                              <th
                                style={{
                                  border: '1px solid #ccc',
                                  borderCollapse: 'collapse',
                                  padding: '0 0.5rem',
                                }}
                              >
                                <FormattedMessage id="VALUE" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {values.map((item, index) => (
                              <tr key={index}>
                                <td
                                  style={{
                                    border: '1px solid #ccc',
                                    borderCollapse: 'collapse',
                                    padding: '0 0.5rem',
                                  }}
                                >
                                  {item.year}/{item.month}
                                </td>
                                <td
                                  style={{
                                    border: '1px solid #ccc',
                                    borderCollapse: 'collapse',
                                    padding: '0 0.5rem',
                                  }}
                                >
                                  {formatter(item.value)}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      );
                    } else
                      return (
                        <span>
                          {formatter ? formatter(rowData[name]) : rowData[name]}
                        </span>
                      );
                  }}
                />
              );
            }
          })
        : []
    );
  }, [isButtonCkicked]);
  const isAktif = code === 'aktifbank' || code === 'param' || code === 'teb';

  const fetchData = async () => {
    setLoading(true);

    try {
      const response = await api.tokenized.post(
        `/filter?page=${lazyParams.page}&itemPerPage=${lazyParams.rows}&query=${globalFilterValue}&sort=${lazyParams.sortField}&desc=${lazyParams.sortOrder === 1 ? true : false}`,
        values_ && values_.length > 0
          ? values_.map((a) => ({
              type: a.type,
              value: a.value,
              name: a.name,
            }))
          : []
      );
      setAnalysis(response.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    fetchData();
  }, [lazyParams, globalFilterValue]);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;

    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <InputField
          name="globalFilterValue"
          type="text"
          onChange={onGlobalFilterChange}
          value={globalFilterValue}
          placeHolder={intl.formatMessage({ id: 'SEARCH' })}
          style={{ width: '15%' }}
        />

        {hasDownloadButton && (
          <DownloadExcelFromUrlButton
            text={intl.formatMessage({
              id: 'EXPORT_TO_EXCEL',
            })}
            size="sm"
            buttonStlye={{ marginLeft: '0.5rem' }}
            iconClassName={icons.download}
            url={downloadUrl}
            headers={[
              {
                header: intl.formatMessage({
                  id: 'PREV_ANL_ANALYSIS_ID',
                }),
                key: 'documentId',
              },
              {
                header: intl.formatMessage({ id: 'analysisOwner' }),
                key: 'userName',
              },
              {
                header: intl.formatMessage({ id: 'analysisCustomerId' }),
                key: 'fullName',
              },
              {
                header: intl.formatMessage({
                  id: 'PREV_ANL_DECLARATION_YEAR_TITLE',
                }),
                key: 'statementYear',
              },
              {
                header: intl.formatMessage({
                  id: 'PREV_ANL_ANALYSIS_DATE',
                }),
                key: 'createdAt',
              },

              {
                header: intl.formatMessage({ id: 'companyAnalysisType' }),
                key: 'providerType',
              },
              {
                header: intl.formatMessage({ id: 'CREDIT_SCORING' }),
                key: 'scoringPoint',
              },
              {
                header: intl.formatMessage({ id: 'SUBTAB_1_3_TITLE1' }),
                key: 'scoringLetter',
              },
            ]}
            filterData={(data, headers) => {
              return data.map((d) => {
                const obj = {};
                headers.forEach((header) => {
                  if (
                    header.key === 'fullName' ||
                    header.key === 'statementYear'
                  ) {
                    if (header.key === 'statementYear') {
                      obj.statementYear = `${d.customer.statementYear}/${d.customer.statementMonth}`;
                    } else obj[header.key] = d.customer[header.key];
                  } else {
                    if (header.key === 'createdAt') {
                      const date = intl.formatDate(d.createdAt);
                      obj.createdAt = date;
                    } else if (header.key === 'providerType') {
                      obj.providerType = providerTypeNums.includes(
                        d.providerType
                      )
                        ? intl.formatMessage({
                            id: d.providerType,
                          })
                        : '';
                    } else obj[header.key] = d[header.key];
                  }
                });

                return obj;
              });
            }}
            fileName={intl.formatMessage({
              id: 'ANALYSES',
            })}
          />
        )}
      </div>
    );
  };
  const onPage = (event) => {
    setLazyParams({ ...event, page: event.page + 1 });
  };

  const onFilter = (event) => {
    console.log('event', event);
    setLazyParams(event);
  };

  const onSort = (event) => {
    setLazyParams((prev) => ({
      ...event,
      page: 1,
    }));
  };

  const handleDelete = (id) => async (e) => {
    e.preventDefault();
    const val = await Swal.fire({
      title: intl.formatMessage({ id: 'ACTIONS.DELETE.TITLE' }),
      text: intl.formatMessage({ id: 'ACTIONS.DELETE.TEXT' }),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: intl.formatMessage({ id: 'ACTIONS.DELETE.CONFIRM' }),
    });

    if (val.value) {
      try {
        await api.tokenized.delete(`/analyze/disable/${id}`);
      } catch (e) {
        console.log(e);
      } finally {
        fetchData();
      }
    }
  };

  const totalRecords = analysis ? analysis.itemCount : 0;
  const footer = (
    <span
      style={{
        position: 'absolute',
        bottom: '20px',
        right: '5px',
      }}
    >
      <FormattedMessage id="FILTERED_ANALYSIS_NUMBER" />: {totalRecords}
    </span>
  );
  return (
    <div className="w-full">
      <div className="flex flex-col">
        <DataTable
          value={analysis ? [...analysis.data] : []}
          className="mt-3"
          scrollable
          loading={loading}
          paginator
          rowsPerPageOptions={[5, 10, 25, 50]}
          onPage={onPage}
          first={lazyParams.first}
          rows={lazyParams.rows}
          totalRecords={totalRecords}
          onSort={onSort}
          sortField={lazyParams.sortField}
          sortOrder={lazyParams.sortOrder}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport"
          currentPageReportTemplate=" "
          emptyMessage={intl.formatMessage({ id: 'NO_DATA_IS_AVAILABLE' })}
          lazy
          header={renderHeader}
          onGlobalFilterChange={onFilter}
          footer={footer}
        >
          <Column
            field="documentId"
            header={<FormattedMessage id="PREV_ANL_ANALYSIS_ID" />}
            sortField="documentId"
            sortable
          />
          <Column
            field="userName"
            header={<FormattedMessage id="analysisOwner" />}
            sortField="userName"
            sortable
          />
          <Column
            field="fullName"
            header={<FormattedMessage id="analysisCustomerId" />}
            sortField="fullName"
            sortable
          />

          <Column
            header={<FormattedMessage id="PREV_ANL_DECLARATION_YEAR_TITLE" />}
            body={(rowData) => {
              return (
                <span>{`${rowData.statementYear}/${rowData.statementMonth}`}</span>
              );
            }}
            field="statementYear"
            sortable
          />
          <Column
            field="createdAt"
            header={<FormattedMessage id="PREV_ANL_ANALYSIS_DATE" />}
            sortField="createdAt"
            body={(rowData) => {
              return (
                <span>
                  {' '}
                  <FormattedDate value={rowData.createdAt} />
                </span>
              );
            }}
            sortable
          />

          <Column
            header={<FormattedMessage id="companyAnalysisType" />}
            body={(rowData) => {
              return rowData.providerType &&
                providerTypeNums.includes(rowData.providerType) ? (
                <span>{<FormattedMessage id={rowData.providerType} />}</span>
              ) : (
                ''
              );
            }}
            field="providerType"
            sortable
          />

          {newColumns}
          <Column
            header={<i className="fas fa-water" />}
            body={(rowData) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '0.5rem',
                  }}
                >
                  <LinkButton
                    url={
                      '/report/' + window.btoa(rowData.documentId) + '/summary'
                    }
                    text={intl.formatMessage({
                      id: 'TO_ANALYSIS',
                    })}
                    size="sm"
                    color="primary"
                    iconClassName={icons.startAnalysis}
                    openNewTab={true}
                  />
                  {isAktif && hasEditButton && (
                    <LinkButton
                      url={
                        '/analysis/' + window.btoa(rowData.documentId) + '/edit'
                      }
                      text={intl.formatMessage({
                        id: 'EDIT',
                      })}
                      size="sm"
                      color="secondary"
                      iconClassName={icons.edit}
                    />
                  )}

                  {hasCustomerPageButton && (
                    <LinkButton
                      url={'/customer/' + rowData.customerId + '/profile'}
                      text={intl.formatMessage({
                        id: 'PROFILE',
                      })}
                      size="sm"
                      color="info"
                      iconClassName={icons.profile}
                      openNewTab={true}
                    />
                  )}
                  {hasDeleteButton && (
                    <DeleteButton
                      onClick={handleDelete(rowData.documentId)}
                      text={intl.formatMessage({
                        id: 'DELETE_ANALYSIS',
                      })}
                      size="sm"
                      color="secondary"
                      iconClassName={icons.delete}
                    />
                  )}
                </div>
              );
            }}
          />
        </DataTable>
      </div>
      {analysis && analysis.data && analysis.data.length === 0 && (
        <div className="text-center mt-4">
          <LinkButton
            url={'/customer/add'}
            text={intl.formatMessage({
              id: 'ADD_NEW_CUSTOMER',
            })}
            size="lg"
            color="success"
            iconClassName={icons.add}
          />
        </div>
      )}
    </div>
  );
};
