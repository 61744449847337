import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { useAuth } from 'react-admin-base';
import { fireSwal } from '../SwalFire';
import ExcelJS from 'exceljs';

export const LinkButton = (props) => {
  const {
    url,
    text,
    size,
    className,
    color,
    iconClassName,
    buttonStyle,
    iconStyle,
    openNewTab,
  } = props;
  const _size = size || 'sm';
  const color_ = color || 'info';

  const linkProps = openNewTab
    ? { to: url, target: '_blank', rel: 'noopener noreferrer' }
    : { to: url };
  return (
    <Link onClick={(e) => e.stopPropagation()} {...linkProps}>
      <Button_
        text={text}
        size={_size}
        className={className}
        color={color_}
        iconClassName={iconClassName}
        buttonStyle={buttonStyle}
        iconStyle={iconStyle}
      />
    </Link>
  );
};

export const DeleteButton = (props) => {
  const { onClick, text, size, className, iconClassName } = props;
  const _size = size || 'sm';

  return (
    <Button_
      onClick={onClick}
      text={text}
      size={_size}
      className={className}
      color="danger"
      icon="fas fa-trash"
      iconClassName={iconClassName}
    />
  );
};

export const Button_ = (props) => {
  const {
    onClick,
    text,
    size,
    className,
    color,
    iconClassName,
    disabled,
    loading,
    id,
    buttonStyle,
    iconStyle,
    type,
  } = props;
  const _size = size || 'sm';
  const color_ = color || 'info';
  const iconClassName_ = loading ? 'fa fa-spinner' : iconClassName;

  return (
    <Button
      color={color_}
      size={_size}
      className={cn(className)}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
      }}
      disabled={disabled}
      loading={loading}
      id={id}
      style={{
        whiteSpace: 'nowrap',
        ...buttonStyle,
      }}
      type={type || 'button'}
    >
      {text}
      {iconClassName && (
        <i
          className={cn(iconClassName_)}
          style={{
            marginLeft: '0.5rem',
            ...iconStyle,
          }}
        ></i>
      )}
    </Button>
  );
};

export const DownloadExcelFromUrlButton = (props) => {
  const {
    filterData,
    text,
    size,
    className,
    iconClassName,
    url,
    headers,
    fileName,
    buttonStyle,
    iconStyle,
  } = props;
  const _size = size || 'sm';

  const [API] = useAuth();

  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const result = await API.tokenized.get(url);
      if (result.status !== 200) {
        throw new Error('Error');
      }
      const data_ = result.data.data || [];
      const filteredData = filterData(data_, headers);
      setData(filteredData || []);
    } catch (e) {
      fireSwal({
        background: '#ffd8df',
        icon: 'error',
        text: e?.response?.data?.message
          ? localStorage.frayjet_frayjet_language === 'en'
            ? e?.response?.data?.message?.enn
            : e?.response?.data?.message?.tr
          : 'Bir hata meydana geldi.',
        timer: 5000,
      });
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    data && data.length > 0 && exportToExcel({ data, headers, fileName });
  }, [data]);

  return (
    <>
      <Button_
        text={text}
        size={_size}
        className={className}
        color="success"
        iconClassName={iconClassName}
        disabled={loading}
        loading={loading}
        onClick={(e) => {
          e.preventDefault();
          fetchData();
        }}
        buttonStyle={buttonStyle}
        iconStyle={iconStyle}
      />
    </>
  );
};

const exportToExcel = async (props) => {
  const { data, headers, fileName } = props;
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sheet1');
  //take key and key2
  console.log('headers: ', headers, 'data: ', data);
  // Sütun başlıkları ekleme ve stil verme
  worksheet.columns = headers;
  data.forEach((d, index) => {
    const row = worksheet.addRow(d);

    // Hücre stilini ayarlama
    row.eachCell((cell) => {
      cell.font = { size: 12 };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });

    if (index === 0) {
      worksheet.getRow(1).font = { bold: true, size: 14 };
      worksheet.getRow(1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFCCCCCC' },
      };
    }
  });

  worksheet.columns.forEach((column) => {
    column.width = 50;
  });

  // Dosyayı indirme
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = `${fileName}.xlsx`;
  link.click();
};
