import React, { useState } from 'react';
import {
  BootstrapTable,
  Actions,
  ActionsColumn,
  Column,
  IdColumn,
  ExcelExportButton,
} from 'react-admin-base-bootstrap';
import { Breadcrumb } from 'react-admin-base-nazox';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { Route, Routes } from 'react-router-dom';
import AddCreditModal from './AddCreditModal';
import { FormattedMessage } from 'react-intl';
import { User } from './User';

export default function ProductTypeList() {
  const [modal, setModal] = useState(false);
  const params = { sort: 'id', desc: true };
  const toggle = () => setModal(!modal);

  return (
    <Routes>
      <Route path=":id/edit" element={<User />} />
      <Route path="create" element={<User />} />

      <Route
        path="*"
        element={
          <Breadcrumb
            title={
              <>
                <FormattedMessage id="ADMIN_PANEL" />
                {' - '}
                <FormattedMessage id="ADD_USER_TITLE" />
              </>
            }
            data={[
              {
                href: '/user',
                name: <FormattedMessage id="MEMBER_LIST" />,
              },
            ]}
          >
            <Card>
              <CardHeader>
                <div>
                  <Button
                    style={{ float: 'right' }}
                    color="primary"
                    onClick={toggle}
                  >
                    <FormattedMessage id="ADD_CREDIT" />
                  </Button>
                  {modal && <AddCreditModal onClose={toggle} />}
                </div>
              </CardHeader>
              <CardBody>
                <BootstrapTable
                  url="/user"
                  add="/user/create"
                  defaultParams={params}
                >
                  <thead>
                    <tr>
                      <IdColumn />
                      <Column sort="first_name">
                        <FormattedMessage id="PROFILE_NAME" />
                      </Column>
                      <Column sort="last_name">
                        <FormattedMessage id="PROFILE_SURNAME" />
                      </Column>
                      <Column sort="email">
                        <FormattedMessage id="PROFILE_EMAIL" />
                      </Column>
                      <ActionsColumn />
                    </tr>
                  </thead>
                  <tbody>
                    {(row) => (
                      <tr key={row.id}>
                        <td className="min text-center">{row.id}</td>
                        <td>{row.first_name}</td>
                        <td>{row.last_name}</td>
                        <td>{row.email}</td>
                        <Actions
                          edit={'/user/' + row.id + '/edit'}
                          del={'/user/' + row.id}
                        />
                      </tr>
                    )}
                  </tbody>
                  <>
                    <ExcelExportButton
                      name="Kullanıcılar"
                      params={{
                        sort: 'id',
                        desc: false,
                      }}
                      header={[
                        'ID',
                        'Adı',
                        'Soyadı',
                        'E-posta Adresi',
                        'Bitiş Tarihi',
                        'Oluşturulma Tarihi',
                      ]}
                      map={(user) => [
                        user.id,
                        user.first_name,
                        user.last_name,
                        user.email,
                        user.expires_at ? new Date(user.expires_at) : '',
                        new Date(user.created_at),
                      ]}
                    />
                  </>
                </BootstrapTable>
              </CardBody>
            </Card>
          </Breadcrumb>
        }
      />
    </Routes>
  );
}
