import React from 'react';
import {
  Card,
  CardBody,
  Label,
  Input,
  Row,
  Col,
  CardFooter,
  Badge,
} from 'reactstrap';
import { icons } from '../Icons/icons';
import { DataTable_ } from './DataTable';
import NumberFormat from 'react-number-format';
import { Button_ } from './ActionButtons/ActionButtons';
import { AsyncPaginateInput_, SelectInput } from './Selects';
import { kobiOptions } from '../pages/Customer/CustomerForm';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import { formatNumber } from '../Util/formatNumber';
import { formatPercentage } from '../Util/formatNumber';
import { FormattedDate } from 'react-intl';

// export type TableContentProps = {
//   hasDownloadButton?: boolean;
// };

const defaultState = {
  analysisOwner: {
    value: [],
    type: 'list-int',
    formatter: '',
  },

  statementYear: {
    value: {
      minValue: '',
      maxValue: '',
    },
    type: 'min-max',
    formatter: '',
  },
  statementMonth: {
    value: {
      minValue: '',
      maxValue: '',
    },
    type: 'min-max',
    formatter: '',
  },
  analysisDate: {
    value: {
      minValue: '',
      maxValue: '',
    },
    type: 'min-max',
    formatter: (a) => <FormattedDate value={a} />,
  },
  sectorCode: {
    value: [],
    type: 'list-string',
    messageId: 'NACE_CODE',
    formatter: '',
  },
  numberOfEmployee: {
    value: {
      minValue: '',
      maxValue: '',
    },
    type: 'min-max',
    messageId: 'NUMBER_OF_EMPLOYEES',
    formatter: (a) => formatNumber(a),
  },
  kobiType: {
    value: [],
    type: 'list-int',
    messageId: 'KOBI_CLASS',
    formatter: (a) => kobiOptions[a],
  },

  tradeRegistrationNumber: {
    value: [],
    type: 'list-string',
    messageId: 'tradeRegistrationNumber',
    formatter: '',
  },

  customer: {
    value: [],
    type: 'list-int',
  },
  currentRatio: {
    value: {
      minValue: '',
      maxValue: '',
    },
    type: 'min-max',
    messageId: 'CURRENT_RATIO',
    formatter: (a) => formatNumber(a),
    section: 'financial-ratios',
  },

  interestCoverageRatio: {
    value: {
      minValue: '',
      maxValue: '',
    },
    type: 'min-max',
    messageId: 'INTEREST_COVERAGE_RATIO',
    formatter: (a) => formatNumber(a),
    section: 'financial-ratios',
  },
  netProfitNetSalesRatio: {
    value: {
      minValue: '',
      maxValue: '',
    },
    type: 'min-max',
    messageId: ['NET_PROFIT', 'NET_SALES'],
    formatter: (a) => formatPercentage(a),
    section: 'financial-ratios',
    numberType: 'percentage',
  },
  salesGrowthRatio: {
    value: {
      minValue: '',
      maxValue: '',
    },
    type: 'min-max',
    messageId: 'SALES_GROWTH_RATE',
    formatter: (a) => formatPercentage(a),
    section: 'financial-ratios',
    numberType: 'percentage',
  },
  bankDebtsEbitdaRatio: {
    value: {
      minValue: '',
      maxValue: '',
    },

    type: 'min-max',
    messageId: ['BANK_DEPTS', 'EBITDA_FOR_AKTIF'],
    formatter: (a) => formatPercentage(a),
    section: 'financial-ratios',
    numberType: 'percentage',
  },
  ebitdaNetSalesRatio: {
    value: {
      minValue: '',
      maxValue: '',
    },
    type: 'min-max',
    messageId: ['EBITDA_FOR_AKTIF', 'NET_SALES'],
    formatter: (a) => formatPercentage(a),
    section: 'financial-ratios',
    numberType: 'percentage',
  },
  bankDebtsSalesRatio: {
    value: {
      minValue: '',
      maxValue: '',
    },
    type: 'min-max',
    messageId: ['BANK_DEPTS', 'SALES'],
    formatter: (a) => formatPercentage(a),
    section: 'financial-ratios',
    numberType: 'percentage',
  },
  equityRatio: {
    value: {
      minValue: '',
      maxValue: '',
    },
    type: 'min-max',
    messageId: 'EQUITY_RATIO',
    formatter: (a) => formatPercentage(a, '', true),
    section: 'financial-ratios',
    numberType: 'percentage',
  },
  netPeriodProfitOrLossRatio: {
    value: {
      minValue: '',
      maxValue: '',
    },
    type: 'min-max',
    messageId: 'NET_PROFIT_OR_LOSS_RATIO',
    formatter: (a) => formatPercentage(a, '', true),
    section: 'financial-ratios',
    numberType: 'percentage',
  },
  equity: {
    value: {
      minValue: '',
      maxValue: '',
    },

    type: 'min-max',
    messageId: 'EQUITY',
    formatter: (a) => formatNumber(a),
    section: 'financial-items',
  },
  netPeriodProfitOrLoss: {
    value: {
      minValue: '',
      maxValue: '',
    },

    type: 'min-max',
    messageId: 'PROFIT_OR_LESS_FINANCIAL_YEAR',
    formatter: (a) => formatNumber(a),
    section: 'financial-items',
  },
};

export const TableContent = (props) => {
  const company = props.company;
  // const [filters, filtersLoading] = useFetch('/filter');
  const [values, setValues] = React.useState(defaultState);
  const [isButtonCkicked, setIsButtonClicked] = React.useState(false);
  const intl = useIntl();
  const [hasValue, setHasValue] = React.useState(false);
  const handleFilter = (e) => {
    e.preventDefault();
    setIsButtonClicked((prev) => !prev);

    const values_ = values;
    Object.entries(values_).map(([key, value]) => {
      if (value.type === 'min-max') {
        if (value.value.minValue && value.value.maxValue) {
          const minValue = value.value.minValue;
          const maxValue = value.value.maxValue;
          if (minValue > maxValue) {
            [value.value.minValue, value.value.maxValue] = [maxValue, minValue];
          }
        }
      }
    });

    setValues(values_);

    setHasValue(
      Object.entries(values).some(([key, value]) => {
        if (value.type === 'min-max') {
          return value.value.minValue || value.value.maxValue;
        } else {
          return value.value.length > 0;
        }
      })
    );
  };

  const handleChangeValues = (nameValue, type) => {
    const { name, value } = nameValue;
    if (type === 'list') {
      setValues((prev) => ({
        ...prev,
        [name]: {
          ...prev[name],
          value: value,
        },
      }));
    } else if (type === 'minValue' || type === 'maxValue') {
      setValues((prev) => ({
        ...prev,
        [name]: {
          ...prev[name],
          value: {
            ...prev[name].value,
            [type]: value,
          },
        },
      }));
    } else {
      setValues((prev) => ({
        ...prev,
        [name]: {
          ...prev[name],
          value: value,
        },
      }));
    }
  };

  // filters_.map((filter) => {
  //   const filterName = filter.name;
  //   if (!values[filterName]) return;
  //   const value = ['analysisOwner', 'customer'].includes(filterName)
  //     ? values[filterName].map((a) => a.id)
  //     : filterName === 'sectorCode'
  //       ? values[filterName].map((a) => a.code)
  //       : filterName === 'kobiType'
  //         ? values[filterName].map((a) => a.value)
  //         : values[filterName];

  //   filter.value = value;
  // });

  const params = Object.entries(values).map(([key, value]) => {
    return {
      name: key,
      value: ['analysisOwner', 'customer'].includes(key)
        ? value.value.map((a) => a.id)
        : key === 'sectorCode'
          ? value.value.map((a) => a.code)
          : key === 'kobiType'
            ? value.value.map((a) => a.value)
            : key === 'statementYear' || key === 'statementMonth'
              ? {
                  minValue: value.value.minValue
                    ? Number(value.value.minValue)
                    : '',
                  maxValue: value.value.maxValue
                    ? Number(value.value.maxValue)
                    : '',
                }
              : value.numberType === 'percentage'
                ? {
                    minValue: value.value.minValue
                      ? Number(value.value.minValue) / 100
                      : '',
                    maxValue: value.value.maxValue
                      ? Number(value.value.maxValue) / 100
                      : '',
                  }
                : value.value,
      type: value.type,
      messageId: value.messageId,
      section: value.section,
      formatter: value.formatter,
      numberType: value.numberType,
    };
  });

  return (
    <div className="w-full">
      <div className="flex flex-col">
        {!hasValue && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
            className="mb-2"
          >
            <Badge color="primary">
              <Label size="lg">
                <FormattedMessage id="FILTER_START" />
              </Label>
            </Badge>
          </div>
        )}{' '}
        <Card>
          <CardBody>
            <Card>
              <CardBody>
                <Row>
                  <Label size="lg" className="text-primary">
                    <FormattedMessage id="ANALYSIS_FILTERS" />
                  </Label>
                </Row>
                <Row>
                  <Col>
                    <Label>
                      {' '}
                      <FormattedMessage id="analysisOwner" />
                    </Label>
                    <AsyncPaginateInput_
                      value={values.analysisOwner.value}
                      isMulti={true}
                      onChange={(e) =>
                        handleChangeValues(
                          {
                            name: 'analysisOwner',
                            value: e,
                          },
                          'list'
                        )
                      }
                      url={`/company/get/users/${company.id}`}
                      filterResponse={(res) => res.data}
                      getOptionValue={(option) => option.id}
                      getOptionLabel={(option) =>
                        `${option.first_name} ${option.last_name}`
                      }
                    />
                  </Col>
                  <Col>
                    <Label>
                      {' '}
                      <FormattedMessage id="PREV_ANL_DECLARATION_YEAR_TITLE" />
                    </Label>{' '}
                    <Row>
                      <Col sm={6}>
                        <Input
                          type="month"
                          placeHolder={intl.formatMessage({
                            id: 'LOWER_LIMIT',
                          })}
                          name="statementYear"
                          value={`${values.statementYear.value.minValue}-${values.statementMonth.value.minValue}`}
                          onChange={(e) => {
                            handleChangeValues(
                              {
                                name: 'statementYear',
                                value: e.target.value
                                  ? e.target.value.split('-')[0]
                                  : '',
                              },
                              'minValue'
                            );
                            handleChangeValues(
                              {
                                name: 'statementMonth',
                                value: e.target.value
                                  ? e.target.value.split('-')[1]
                                  : '',
                              },
                              'minValue'
                            );
                          }}
                        ></Input>
                      </Col>
                      <Col sm={6}>
                        <Input
                          type="month"
                          placeHolder={intl.formatMessage({
                            id: 'UPPER_LIMIT',
                          })}
                          name="statementYear"
                          value={`${values.statementYear.value.maxValue}-${values.statementMonth.value.maxValue}`}
                          onChange={(e) => {
                            handleChangeValues(
                              {
                                name: 'statementYear',
                                value: e.target.value
                                  ? e.target.value.split('-')[0]
                                  : '',
                              },
                              'maxValue'
                            );
                            handleChangeValues(
                              {
                                name: 'statementMonth',
                                value: e.target.value
                                  ? e.target.value.split('-')[1]
                                  : '',
                              },
                              'maxValue'
                            );
                          }}
                        ></Input>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Label>
                      <FormattedMessage id="PREV_ANL_ANALYSIS_DATE" />
                    </Label>
                    <Row>
                      <Col sm={6}>
                        <Input
                          type="month"
                          lassName="mb-2"
                          placeHolder={intl.formatMessage({
                            id: 'LOWER_LIMIT',
                          })}
                          name="analysisDate"
                          value={values.analysisDate.value.minValue}
                          onChange={(e) =>
                            handleChangeValues(
                              {
                                name: 'analysisDate',
                                value: e.target.value,
                              },
                              'minValue'
                            )
                          }
                        ></Input>
                      </Col>
                      <Col sm={6}>
                        <Input
                          type="month"
                          lassName="mb-2"
                          placeHolder={intl.formatMessage({
                            id: 'UPPER_LIMIT',
                          })}
                          name="analysisDate"
                          value={values.analysisDate.value.maxValue}
                          onChange={(e) =>
                            handleChangeValues(
                              {
                                name: 'analysisDate',
                                value: e.target.value,
                              },
                              'maxValue'
                            )
                          }
                        ></Input>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Row>
                  <Label size="lg" className="text-primary">
                    <FormattedMessage id="TAG_DATA" />
                  </Label>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Label>
                      <FormattedMessage id="analysisCustomerId" />
                    </Label>
                    <AsyncPaginateInput_
                      value={values.customer.value}
                      onChange={(e) => {
                        handleChangeValues(
                          {
                            name: 'customer',
                            value: e,
                          },
                          'list'
                        );
                      }}
                      url="/customer/get/all"
                      filterResponse={(res) => res.data}
                      getOptionValue={(option) => option.id}
                      getOptionLabel={(option) => option.name}
                      isMulti={true}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Label>
                      <FormattedMessage id="NACE_CODE" />
                    </Label>

                    <AsyncPaginateInput_
                      value={values.sectorCode.value}
                      isMulti={true}
                      filterResponse={(res) => res.data}
                      onChange={(newValue, actionMeta) => {
                        let value = newValue;
                        const action = actionMeta.action;

                        if (action === 'remove-value') {
                          const removedValue = actionMeta.removedValue;

                          value = value.filter(
                            (a) => a.code !== removedValue.code
                          );
                        }
                        {
                          handleChangeValues(
                            {
                              name: 'sectorCode',
                              value: value,
                            },
                            'list'
                          );
                        }
                      }}
                      url="/sector/get/paginate/all"
                      getOptionLabel={(a) =>
                        `${a.code}-${
                          localStorage.frayjet_frayjet_language === 'en'
                            ? a.enName
                            : a.name
                        }`
                      }
                      getOptionValue={(a) => <FormattedMessage id={a.code} />}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Label>
                      <FormattedMessage id="NUMBER_OF_EMPLOYEES" />
                    </Label>

                    <Row>
                      <Col>
                        <NumberFormat
                          allowNegative={false}
                          className="form-control"
                          thousandSeparator={'.'}
                          decimalSeparator={false}
                          placeholder={intl.formatMessage({
                            id: 'LOWER_LIMIT',
                          })}
                          name="numberOfEmployee.value.minValue"
                          value={values.numberOfEmployee.value.minValue}
                          onValueChange={(e) =>
                            handleChangeValues(
                              {
                                name: 'numberOfEmployee',
                                value: e.floatValue,
                              },
                              'minValue'
                            )
                          }
                        />
                      </Col>
                      <Col>
                        <NumberFormat
                          allowNegative={false}
                          className="form-control"
                          thousandSeparator={'.'}
                          decimalSeparator={false}
                          placeholder={intl.formatMessage({
                            id: 'UPPER_LIMIT',
                          })}
                          name="numberOfEmployee.value.maxValue"
                          value={values.numberOfEmployee.value.maxValue}
                          onValueChange={(e) =>
                            handleChangeValues(
                              {
                                name: 'numberOfEmployee',
                                value: e.floatValue,
                              },
                              'maxValue'
                            )
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Label>
                      <FormattedMessage id="KOBI_CLASS" />
                    </Label>

                    <SelectInput
                      value={values.kobiType.value}
                      onChange={(e) => {
                        handleChangeValues(
                          {
                            name: 'kobiType',
                            value: e,
                          },
                          'list'
                        );
                      }}
                      options={kobiOptions.map((o, i) => ({
                        value: i,
                        label: o,
                      }))}
                      isMulti={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Label>
                      <FormattedMessage id="tradeRegistrationNumber" />
                    </Label>

                    <Input
                      name="tradeRegistrationNumber"
                      value={values.tradeRegistrationNumber.value[0] || ''}
                      type="text"
                      onChange={(e) => {
                        /^[0-9-]*$/.test(e.target.value) &&
                          handleChangeValues(
                            {
                              name: 'tradeRegistrationNumber',
                              value: e.target.value ? [e.target.value] : [],
                            },
                            'list'
                          );
                      }}
                    ></Input>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Row>
                  <Label size="lg" className="text-primary">
                    <FormattedMessage id="FINANCIAL_RATIOS" />
                  </Label>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Label>
                      <FormattedMessage id="CURRENT_RATIO" />
                    </Label>
                    <Row>
                      <Col>
                        <NumberFormat
                          allowNegative={true}
                          className="form-control"
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          placeholder={intl.formatMessage({
                            id: 'LOWER_LIMIT',
                          })}
                          name="  "
                          value={values.currentRatio.value.minValue}
                          onValueChange={(e) =>
                            handleChangeValues(
                              {
                                name: 'currentRatio',
                                value: e.floatValue,
                              },
                              'minValue'
                            )
                          }
                        />
                      </Col>
                      <Col>
                        <NumberFormat
                          name="currentRatio"
                          value={values.currentRatio.value.maxValue}
                          onValueChange={(e) =>
                            handleChangeValues(
                              {
                                name: 'currentRatio',
                                value: e.floatValue,
                              },
                              'maxValue'
                            )
                          }
                          allowNegative={true}
                          className="form-control"
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          placeholder={intl.formatMessage({
                            id: 'UPPER_LIMIT',
                          })}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Label>
                      <FormattedMessage id="INTEREST_COVERAGE_RATIO" />
                    </Label>
                    <Row>
                      <Col>
                        <NumberFormat
                          name="interestCoverageRatio"
                          value={values.interestCoverageRatio.value.minValue}
                          onValueChange={(e) =>
                            handleChangeValues(
                              {
                                name: 'interestCoverageRatio',
                                value: e.floatValue,
                              },
                              'minValue'
                            )
                          }
                          allowNegative={true}
                          className="form-control"
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          placeholder={intl.formatMessage({
                            id: 'LOWER_LIMIT',
                          })}
                        />
                      </Col>
                      <Col>
                        <NumberFormat
                          name="interestCoverageRatio"
                          value={values.interestCoverageRatio.value.maxValue}
                          onValueChange={(e) =>
                            handleChangeValues(
                              {
                                name: 'interestCoverageRatio',
                                value: e.floatValue,
                              },
                              'maxValue'
                            )
                          }
                          allowNegative={true}
                          className="form-control"
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          placeholder={intl.formatMessage({
                            id: 'UPPER_LIMIT',
                          })}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Label>
                      <FormattedMessage id="NET_PROFIT" /> /{' '}
                      <FormattedMessage id="NET_SALES" /> (%)
                    </Label>
                    <Row>
                      <Col>
                        <NumberFormat
                          name="netProfitNetSalesRatio"
                          value={values.netProfitNetSalesRatio.value.minValue}
                          onValueChange={(e) => {
                            handleChangeValues(
                              {
                                name: 'netProfitNetSalesRatio',
                                value: e.floatValue,
                              },
                              'minValue'
                            );
                          }}
                          allowNegative={true}
                          className="form-control"
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          placeholder={intl.formatMessage({
                            id: 'LOWER_LIMIT',
                          })}
                          prefix={'%'}
                        />
                      </Col>
                      <Col>
                        <NumberFormat
                          name="netProfitNetSalesRatio"
                          value={values.netProfitNetSalesRatio.value.maxValue}
                          onValueChange={(e) =>
                            handleChangeValues(
                              {
                                name: 'netProfitNetSalesRatio',
                                value: e.floatValue,
                              },
                              'maxValue'
                            )
                          }
                          allowNegative={true}
                          className="form-control"
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          placeholder={intl.formatMessage({
                            id: 'UPPER_LIMIT',
                          })}
                          prefix={'%'}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Label>
                      <FormattedMessage id="SALES_GROWTH_RATE" /> (%)
                    </Label>

                    <Row>
                      <Col>
                        <NumberFormat
                          name="salesGrowthRatio"
                          value={values.salesGrowthRatio.value.minValue || ''}
                          onValueChange={(e) =>
                            handleChangeValues(
                              {
                                name: 'salesGrowthRatio',
                                value: e.floatValue,
                              },
                              'minValue'
                            )
                          }
                          allowNegative={true}
                          className="form-control"
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          placeholder={intl.formatMessage({
                            id: 'LOWER_LIMIT',
                          })}
                          prefix={'%'}
                        />
                      </Col>

                      <Col>
                        <NumberFormat
                          name="salesGrowthRatio"
                          value={values.salesGrowthRatio.value.maxValue || ''}
                          onValueChange={(e) =>
                            handleChangeValues(
                              {
                                name: 'salesGrowthRatio',
                                value: e.floatValue,
                              },
                              'maxValue'
                            )
                          }
                          allowNegative={true}
                          className="form-control"
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          placeholder={intl.formatMessage({
                            id: 'UPPER_LIMIT',
                          })}
                          prefix={'%'}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Label>
                      <FormattedMessage id="BANK_DEPTS" /> / EBITDA (%)
                    </Label>

                    <Row>
                      <Col>
                        <NumberFormat
                          name="bankDebtsEbitdaRatio"
                          value={values.bankDebtsEbitdaRatio.value.minValue}
                          onValueChange={(e) =>
                            handleChangeValues(
                              {
                                name: 'bankDebtsEbitdaRatio',
                                value: e.floatValue,
                              },
                              'minValue'
                            )
                          }
                          allowNegative={true}
                          className="form-control"
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          placeHolder={intl.formatMessage({
                            id: 'LOWER_LIMIT',
                          })}
                          prefix="%"
                        />
                      </Col>

                      <Col>
                        <NumberFormat
                          name="bankDebtsEbitdaRatio"
                          value={values.bankDebtsEbitdaRatio.value.maxValue}
                          onValueChange={(e) =>
                            handleChangeValues(
                              {
                                name: 'bankDebtsEbitdaRatio',
                                value: e.floatValue,
                              },
                              'maxValue'
                            )
                          }
                          allowNegative={true}
                          className="form-control"
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          placeholder={intl.formatMessage({
                            id: 'UPPER_LIMIT',
                          })}
                          prefix="%"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Label>
                      EBITDA / <FormattedMessage id="NET_SALES" /> (%)
                    </Label>

                    <Row>
                      <Col>
                        <NumberFormat
                          name="ebitdaNetSalesRatio"
                          value={
                            values.ebitdaNetSalesRatio.value.minValue || ''
                          }
                          onValueChange={(e) =>
                            handleChangeValues(
                              {
                                name: 'ebitdaNetSalesRatio',
                                value: e.floatValue,
                              },
                              'minValue'
                            )
                          }
                          allowNegative={true}
                          className="form-control"
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          placeHolder={intl.formatMessage({
                            id: 'LOWER_LIMIT',
                          })}
                          prefix="%"
                        />
                      </Col>

                      <Col>
                        <NumberFormat
                          name="ebitdaNetSalesRatio"
                          value={
                            values.ebitdaNetSalesRatio.value.maxValue || ''
                          }
                          onValueChange={(e) =>
                            handleChangeValues(
                              {
                                name: 'ebitdaNetSalesRatio',
                                value: e.floatValue,
                              },
                              'maxValue'
                            )
                          }
                          allowNegative={true}
                          className="form-control"
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          placeholder={intl.formatMessage({
                            id: 'UPPER_LIMIT',
                          })}
                          prefix="%"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col sm={6}>
                    <Label>
                      <FormattedMessage id="BANK_DEPTS" /> /{' '}
                      <FormattedMessage id="SALES" /> (%)
                    </Label>

                    <Row>
                      <Col>
                        <NumberFormat
                          name="bankDebtsSalesRatio"
                          value={
                            values.bankDebtsSalesRatio.value.minValue || ''
                          }
                          onValueChange={(e) =>
                            handleChangeValues(
                              {
                                name: 'bankDebtsSalesRatio',
                                value: e.floatValue,
                              },
                              'minValue'
                            )
                          }
                          allowNegative={true}
                          className="form-control"
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          placeHolder={intl.formatMessage({
                            id: 'LOWER_LIMIT',
                          })}
                          prefix="%"
                        />
                      </Col>

                      <Col>
                        <NumberFormat
                          name="bankDebtsSalesRatio"
                          value={
                            values.bankDebtsSalesRatio.value.maxValue || ''
                          }
                          onValueChange={(e) =>
                            handleChangeValues(
                              {
                                name: 'bankDebtsSalesRatio',
                                value: e.floatValue,
                              },
                              'maxValue'
                            )
                          }
                          allowNegative={true}
                          className="form-control"
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          placeholder={intl.formatMessage({
                            id: 'UPPER_LIMIT',
                          })}
                          prefix="%"
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col sm={6}>
                    <Label>
                      <FormattedMessage id="EQUITY_RATIO" />
                      (%)
                    </Label>

                    <Row>
                      <Col>
                        <NumberFormat
                          name="equityRatio"
                          value={values.equityRatio.value.minValue || ''}
                          onValueChange={(e) =>
                            handleChangeValues(
                              {
                                name: 'equityRatio',
                                value: e.floatValue,
                              },
                              'minValue'
                            )
                          }
                          allowNegative={true}
                          className="form-control"
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          placeHolder={intl.formatMessage({
                            id: 'LOWER_LIMIT',
                          })}
                          prefix="%"
                        />
                      </Col>

                      <Col>
                        <NumberFormat
                          name="equityRatio"
                          value={values.equityRatio.value.maxValue || ''}
                          onValueChange={(e) =>
                            handleChangeValues(
                              {
                                name: 'equityRatio',
                                value: e.floatValue,
                              },
                              'maxValue'
                            )
                          }
                          allowNegative={true}
                          className="form-control"
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          placeholder={intl.formatMessage({
                            id: 'UPPER_LIMIT',
                          })}
                          prefix="%"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col sm={6}>
                    <Label>
                      <FormattedMessage id="NET_PROFIT_OR_LOSS_RATIO" /> (%)
                    </Label>

                    <Row>
                      <Col>
                        <NumberFormat
                          name="netPeriodProfitOrLossRatio"
                          value={
                            values.netPeriodProfitOrLossRatio.value.minValue ||
                            ''
                          }
                          onValueChange={(e) =>
                            handleChangeValues(
                              {
                                name: 'netPeriodProfitOrLossRatio',
                                value: e.floatValue,
                              },
                              'minValue'
                            )
                          }
                          allowNegative={true}
                          className="form-control"
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          placeHolder={intl.formatMessage({
                            id: 'LOWER_LIMIT',
                          })}
                          prefix="%"
                        />
                      </Col>

                      <Col>
                        <NumberFormat
                          name="netPeriodProfitOrLossRatio"
                          value={
                            values.netPeriodProfitOrLossRatio.value.maxValue ||
                            ''
                          }
                          onValueChange={(e) =>
                            handleChangeValues(
                              {
                                name: 'netPeriodProfitOrLossRatio',
                                value: e.floatValue,
                              },
                              'maxValue'
                            )
                          }
                          allowNegative={true}
                          className="form-control"
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          placeholder={intl.formatMessage({
                            id: 'UPPER_LIMIT',
                          })}
                          prefix="%"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <Row>
                  <Label size="lg" className="text-primary">
                    <FormattedMessage id="FINANCIAL_ITEMS" />
                  </Label>
                </Row>
                <Row>
                  <Col>
                    <Label>
                      <FormattedMessage id="EQUITY" /> (
                      <FormattedMessage id="POSITIVE" />
                      /<FormattedMessage id="NEGATIVE" />)
                    </Label>
                    <Row>
                      <Col>
                        <NumberFormat
                          name="equity"
                          value={values.equity.value.minValue}
                          onValueChange={(e) =>
                            handleChangeValues(
                              {
                                name: 'equity',
                                value: e.floatValue,
                              },
                              'minValue'
                            )
                          }
                          allowNegative={true}
                          className="form-control"
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          placeholder={intl.formatMessage({
                            id: 'LOWER_LIMIT',
                          })}
                        />
                      </Col>
                      <Col>
                        <NumberFormat
                          name="equity"
                          value={values.equity.value.maxValue}
                          onValueChange={(e) =>
                            handleChangeValues(
                              {
                                name: 'equity',
                                value: e.floatValue,
                              },
                              'maxValue'
                            )
                          }
                          allowNegative={true}
                          className="form-control"
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          placeholder={intl.formatMessage({
                            id: 'UPPER_LIMIT',
                          })}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Label>
                      {' '}
                      <FormattedMessage id="PROFIT_OR_LESS_FINANCIAL_YEAR" /> (
                      <FormattedMessage id="POSITIVE" />
                      /<FormattedMessage id="NEGATIVE" />)
                    </Label>

                    <Row>
                      <Col>
                        <NumberFormat
                          allowNegative={true}
                          className="form-control"
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          placeholder={intl.formatMessage({
                            id: 'LOWER_LIMIT',
                          })}
                          name="netPeriodProfitOrLoss"
                          value={values.netPeriodProfitOrLoss.value.minValue}
                          onValueChange={(e) =>
                            handleChangeValues(
                              {
                                name: 'netPeriodProfitOrLoss',
                                value: e.floatValue,
                              },
                              'minValue'
                            )
                          }
                        />
                      </Col>
                      <Col>
                        <NumberFormat
                          allowNegative={true}
                          className="form-control"
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          placeholder={intl.formatMessage({
                            id: 'UPPER_LIMIT',
                          })}
                          value={values.netPeriodProfitOrLoss.value.maxValue}
                          onValueChange={(e) =>
                            handleChangeValues(
                              {
                                name: 'netPeriodProfitOrLoss',
                                value: e.floatValue,
                              },
                              'maxValue'
                            )
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </CardBody>
          <CardFooter>
            <Row>
              <Col sm={1}>
                <Button_
                  text={intl.formatMessage({
                    id: 'FILTER',
                  })}
                  color="primary"
                  iconClassName={icons.filter}
                  onClick={handleFilter}
                />
              </Col>
              <Col sm={2}>
                <Button_
                  text={intl.formatMessage({
                    id: 'RESET_FILTERS',
                  })}
                  color="danger"
                  iconClassName={icons.delete}
                  onClick={(e) => {
                    e.preventDefault();
                    setValues(defaultState);
                    setTimeout(() => {
                      setIsButtonClicked((prev) => !prev);
                    }, 1000);
                    setHasValue(false);
                  }}
                />
              </Col>
            </Row>
          </CardFooter>
        </Card>
        {hasValue && (
          <DataTable_
            code={company.code}
            values={params}
            isButtonCkicked={isButtonCkicked}
            openNewTab={true}
            hasDownloadButton={false}
            hasEditButton={false}
            hasCustomerPageButton={true}
          />
        )}
      </div>
    </div>
  );
};
