import React from 'react';
import { Breadcrumb } from 'react-admin-base-nazox';
import { Card, CardBody, Row, Button } from 'reactstrap';
import { useFetch } from 'react-admin-base';
import { TotalAnalyzes } from './TotalAnalyzes';
import { FinancialScoring } from './FinancialScoring';
import { KpiAnalyzesTable } from './KpiAnalyzesTable';
import { getMonth, getYear } from '../../../Util/Constants';
import { useUser } from '../../../Components/UserProvider';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Alerts } from '../../../Components/Alerts';

export const PreviousAnalysis = () => {
  const [prevAnalyzes, loading] = useFetch('/report/analyze/distribution');
  const [scoringVal, loading2] = useFetch('/analyze/scoringpoints/');
  const user = useUser();
  const [company, loading4] = useFetch(`/company/get/user/${user.id}`);
  const navigate = useNavigate();
  const isLoading = loading || loading2 || loading4;
  const companyCode = company && company.code;
  const totalAnalyzesData = {};
  const financialScoringData = {};
  const scoringLetters = {};
  if (scoringVal && scoringVal.dataList)
    scoringVal.dataList.forEach((v) => (scoringLetters[v.riskLevel] = ''));

  if (prevAnalyzes) {
    prevAnalyzes.forEach((e) => {
      // calculates total analysis data according to year and months

      if (e.createdAt) {
        totalAnalyzesData[getYear(e.createdAt)] = {
          ...totalAnalyzesData[getYear(e.createdAt)],
          [getMonth(e.createdAt)]:
            ((totalAnalyzesData[getYear(e.createdAt)] &&
              totalAnalyzesData[getYear(e.createdAt)][getMonth(e.createdAt)]) ||
              0) + 1,
        };
      }
      // calculates financial score ratios according to sectors and scoring letter

      if (e.sectorCode && e.scoringLetter) {
        financialScoringData[e.sectorCode] = {
          ...scoringLetters,
          ...financialScoringData[e.sectorCode],
          [e.scoringLetter]:
            ((financialScoringData[e.sectorCode] &&
              financialScoringData[e.sectorCode][e.scoringLetter]) ||
              0) + 1,
        };
      }
    });
  }
  return (
    <Breadcrumb
      title=<FormattedMessage id="PREV_ANALYZES_GRAPHS" />
      data={[
        {
          href: '/prev-analyzes',
          name: <FormattedMessage id="PREV_ANALYZES_GRAPHS" />,
        },
      ]}
    >
      <Card>
        <CardBody>
          {!isLoading ? (
            !prevAnalyzes ? (
              <>
                <FormattedMessage id="NO_CHARTS" />
                <Button
                  style={{ marginLeft: '1em' }}
                  size="sm"
                  color="success"
                  className="fas fa-chart-pie me-2"
                  onClick={() => navigate('/analysis/create')}
                />
              </>
            ) : (
              <>
                {!!Object.keys(totalAnalyzesData).length && (
                  <>
                    <Row>
                      <TotalAnalyzes totalAnalyzes={totalAnalyzesData} />
                    </Row>{' '}
                    <hr />
                  </>
                )}

                {!!Object.keys(financialScoringData).length && (
                  <>
                    <Row>
                      <FinancialScoring
                        financialScoring={financialScoringData}
                      />
                    </Row>
                    <hr />
                  </>
                )}
                {companyCode === 'socar' && (
                  <Row>
                    <KpiAnalyzesTable />
                  </Row>
                )}
              </>
            )
          ) : (
            <Alerts
              color="primary"
              className="fas fa-spin fa-spinner"
              messageId={'LOADING'}
              label=""
            />
          )}
        </CardBody>
      </Card>
    </Breadcrumb>
  );
};

export default PreviousAnalysis;
