import React from 'react';
import { Breadcrumb } from 'react-admin-base-nazox';
import { Card, CardBody } from 'reactstrap';
import { Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
//import 'bootstrap/  ';
import { CustomerInfo } from './CustomerInfo';
import { CustomerAnalysisTable } from '../../Components/tables/customer-profile/CustomerAnalysisTable';
import { CustomerFinancialsContent } from '../../Components/customer-profile/CustomerFinancialsContent';
import { CustomerFilesContent } from '../../Components/customer-profile/CustomerFileContent';
import { CustomersTable_ } from '../../Components/tables/customer-profile/CustomersTable_';
import { useAuth } from 'react-admin-base';
import { Alerts } from '../../Components/Alerts';

export const CustomerProfile = () => {
  const [API] = useAuth();
  const { id } = useParams();

  const [customer, setCustomer] = React.useState({});
  const [customerLoading, setCustomerLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchData = async () => {
      await API.tokenized
        .get(`/customer/get/${id}`)
        .then((a) => {
          setCustomer(
            a.data.phoneNumber && a.data.taxNumber
              ? {
                  ...a.data,
                  phoneNumber: a.data.phoneNumber.toString().replace('+', ''),
                  number: a.data.number.toString().replace('+', ''),
                  sector: a.data.sector?.code ? { ...a.data.sector } : null,
                  groupId: {
                    label: a.data.groupName,
                    value: a.data.groupId,
                  },
                }
              : {
                  ...a.data,
                  sector: a.data.sector?.code ? { ...a.data.sector } : null,
                  groupId: {
                    label: a.data.groupName,
                    value: a.data.groupId,
                  },
                }
          );
        })
        .finally(() => setCustomerLoading(false));
    };

    if (id) fetchData();
  }, [id]);

  return (
    <Breadcrumb
      title={'Müşteri Profil'}
      data={[
        {
          href: '/customers',
          name: <FormattedMessage id="CUSTOMERS" />,
        },
        {
          href: `/customer/${id}/profile`,
          name: 'Müşteri Profili',
        },
      ]}
    >
      {customerLoading ? (
        <Alerts
          color="primary"
          className="fas fa-spin fa-spinner"
          messageId="LOADING"
          label=""
        />
      ) : (
        <Card>
          <CardBody>
            {customer && <CustomerInfo customer={customer} />}
            <hr />

            <div>
              {customer && (
                <Tabs
                  id="controlled-tab-example0"
                  className="mb-3"
                  unmountOnExit={true}
                  mountOnEnter={true}
                  transition={false}
                >
                  {customer.isGroupCompany && (
                    <Tab
                      eventKey="customers"
                      title={<FormattedMessage id="GROUP_FIRMS" />}
                    >
                      <CustomersTable_ groupId={customer.id} />
                    </Tab>
                  )}

                  {!customer.isGroupCompany && (
                    <Tab
                      eventKey="customer-analysis"
                      title={<FormattedMessage id="CUSTOMER_ANALYSIS" />}
                    >
                      <CustomerAnalysisTable id={id} />
                    </Tab>
                  )}
                  {!customer.isGroupCompany && (
                    <Tab
                      eventKey="documents"
                      title={<FormattedMessage id="FILES" />}
                    >
                      <CustomerFilesContent id={id} />
                    </Tab>
                  )}

                  {!customer.isGroupCompany && (
                    <Tab
                      eventKey="financials"
                      title={<FormattedMessage id="FINANCIALS" />}
                    >
                      <CustomerFinancialsContent customerId={id} />
                    </Tab>
                  )}
                </Tabs>
              )}
            </div>
          </CardBody>
        </Card>
      )}
    </Breadcrumb>
  );
};
