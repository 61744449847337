import React from 'react';
import { ApiSelect } from 'react-admin-base-bootstrap';
import { FormattedMessage } from 'react-intl';
import { AsyncPaginate } from 'react-select-async-paginate';
import { useAuth } from 'react-admin-base';
import Select from 'react-select';

export function CategorySelect(props) {
  return (
    <ApiSelect
      {...props}
      placeholder={<FormattedMessage id="NEW_ANL_PLACEHOLDER_TEXT" />}
      url="/sector/get/paginate/all"
      getOptionLabel={(a) =>
        `${a.code}-${
          localStorage.frayjet_frayjet_language === 'en' ? a.enName : a.name
        }`
      }
      getOptionValue={(a) => <FormattedMessage id={a.code} />}
      fullWidth={false}
      noOptionsMessage={() => <FormattedMessage id={'NO_OPTIONS'} />}
      loadingMessage={() => <FormattedMessage id={'LOADING'} />}
      filterOption={(option, searchText) => {
        const label = option.label.toLocaleLowerCase('tr-TR');
        const text = searchText.toLocaleLowerCase('tr-TR');
        if (label.includes(text)) {
          return true;
        } else {
          return false;
        }
      }}

      //disabled={props.disabled}
    />
  );
}

export function UserSelect(props) {
  return (
    <ApiSelect
      placeholder={<FormattedMessage id="NEW_ANL_PLACEHOLDER_TEXT" />}
      url="/user"
      getOptionValue={(a) => a.first_name + ' ' + a.last_name}
      {...props}
    >
      {(row) => <>{row.first_name + ' ' + row.last_name}</>}
    </ApiSelect>
  );
}

export const AsyncPaginateInput_ = (props) => {
  const {
    value,
    onChange,
    filterOptions,
    url,
    filterResponse,
    getOptionLabel,
    getOptionValue,
    filterOption,
    noPagination,
    disabled,
    isMulti,
    filter,
  } = props;

  const [api] = useAuth();

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    const res = await api.tokenized.get(
      `${url}?page=${page}&query=${searchQuery}`
    );
    const filtereddResponseData = filterResponse
      ? filterResponse(res.data)
      : res.data;

    return {
      options: filtereddResponseData || [],
      hasMore: noPagination ? false : filtereddResponseData.length >= 1,
      additional: {
        page: page + 1,
      },
    };
  };

  const customOption = (props) => {
    console.log('props:');
    return (
      <div
        style={{
          position: 'relative', // Kutu içeriğe göre genişleyecek
          marginTop: '-25px',
        }}
      >
        {' '}
        {/* title özelliği burada value olarak ekleniyor */}
        {props.children}
      </div>
    );
  };

  return (
    <AsyncPaginate
      value={value}
      onChange={onChange}
      loadOptions={loadOptions}
      additional={{
        page: 1,
      }}
      placeholder={<FormattedMessage id={'NEW_ANL_PLACEHOLDER_TEXT'} />}
      isClearable
      loadingMessage={() => <FormattedMessage id={'LOADING'} />}
      noOptionsMessage={() => <FormattedMessage id={'NO_OPTIONS'} />}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      isMulti={isMulti}
      filterOption={filterOption}
      filterOptions={filterOptions}
      disabled={disabled}
      filter={filter}
      components={{
        SingleValue: customOption, // customOption bileşenini kullanıyoruz
      }}
    />
  );
};

export const SelectInput = (props) => {
  const { onChange, options, value, isMulti } = props;

  return (
    <Select
      onChange={onChange}
      options={options}
      value={value}
      isMulti={isMulti}
      placeholder={<FormattedMessage id={'NEW_ANL_PLACEHOLDER_TEXT'} />}
    />
  );
};
