import {
  BootstrapDataTable,
  Column,
  ExcelExportButton,
} from 'react-admin-base-bootstrap';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
const params = { sort: 'documentId', desc: true };
export const KpiAnalyzesTable = () => {
  const intl = useIntl();
  return (
    <BootstrapDataTable
      noStrip
      url="/report/analyze/kpi"
      defaultParams={params}
    >
      <thead>
        <tr>
          <th>
            <i className="fas fa-water" />
          </th>
          <Column sort="documentId">
            <FormattedMessage id="documentId" />
          </Column>
          <Column sort="segment">
            <FormattedMessage id="segment" />
          </Column>
          <Column sort="sapNo">
            <FormattedMessage id="sapNo" />
          </Column>
          <Column sort="analysisCompanyTitle">
            <FormattedMessage id="analysisCompanyTitle" />
          </Column>
          <Column sort="analysisOwner">
            <FormattedMessage id="analysisOwner" />
          </Column>
          <Column sort="projectName">
            <FormattedMessage id="projectName" />
          </Column>
          <Column sort="companyAnalysisType">
            <FormattedMessage id="companyAnalysisType" />
          </Column>
          <Column sort="documentType">
            <FormattedMessage id="type" />
          </Column>
          <Column sort="analysisRequestDate">
            <FormattedMessage id="analysisRequestDate" />
          </Column>
          <Column sort="financialsCompleteDate">
            <FormattedMessage id="financialsCompleteDate" />
          </Column>
          <Column sort="nonFinancialsCompleteDate">
            <FormattedMessage id="nonFinancialsCompleteDate" />
          </Column>
          <Column sort="analysisCompleteDate">
            <FormattedMessage id="analysisCompleteDate" />
          </Column>
          <Column sort="completionDay">
            <FormattedMessage id="completionDay" />
          </Column>
          <Column sort="financialScore">
            <FormattedMessage id="FINANCIAL_POINT" />
          </Column>
        </tr>
      </thead>
      <tbody>
        {(row, j) => {
          return (
            <React.Fragment key={j}>
              <tr>
                <td>
                  <Link
                    to={'/report/' + window.btoa(row.documentId) + '/summary'}
                  >
                    <Button outline={true} color="primary">
                      <i className="fas fa-eye" />{' '}
                    </Button>
                  </Link>
                </td>
                <td>{row.documentId}</td>
                <td>{row.segment}</td>
                <td>{row.sapNo}</td>
                <td>{row.analysisCompanyTitle}</td>
                <td>{row.analysisOwner}</td>
                <td>{row.projectName}</td>
                <td>
                  {row.companyAnalysisType
                    ? intl.formatMessage({ id: row.companyAnalysisType })
                    : ''}
                </td>
                <td>
                  {row.documentType
                    ? intl.formatMessage({ id: row.documentType })
                    : ''}
                </td>
                <td>{row.analysisRequestDate?.split('T')[0]}</td>
                <td>{row.financialsCompleteDate?.split('T')[0]}</td>
                <td>{row.nonFinancialsCompleteDate?.split('T')[0]}</td>
                <td>{row.analysisCompleteDate?.split('T')[0]}</td>
                <td>{row.completionDay}</td>
                <td>
                  {row.financialScore ? row.financialScore.toFixed(2) : ''}
                </td>
              </tr>
            </React.Fragment>
          );
        }}
      </tbody>
      <ExcelExportButton
        name="Analizler"
        params={{
          sort: 'documentId',
          desc: false,
        }}
        header={[
          intl.formatMessage({ id: 'documentId' }),
          intl.formatMessage({ id: 'segment' }),
          intl.formatMessage({ id: 'sapNo' }),
          intl.formatMessage({ id: 'analysisCompanyTitle' }),
          intl.formatMessage({ id: 'analysisOwner' }),
          intl.formatMessage({ id: 'projectName' }),
          intl.formatMessage({ id: 'companyAnalysisType' }),
          intl.formatMessage({ id: 'type' }),
          intl.formatMessage({ id: 'analysisRequestDate' }),
          intl.formatMessage({ id: 'financialsCompleteDate' }),
          intl.formatMessage({ id: 'nonFinancialsCompleteDate' }),
          intl.formatMessage({ id: 'analysisCompleteDate' }),
          intl.formatMessage({ id: 'completionDay' }),
          intl.formatMessage({ id: 'FINANCIAL_POINT' }),
        ]}
        map={(row) => [
          row.documentId,
          row.segment,
          row.sapNo,
          row.analysisCompanyTitle,
          row.analysisOwner,
          row.projectName,
          row.companyAnalysisType
            ? intl.formatMessage({ id: row.companyAnalysisType })
            : '',
          row.documentType ? intl.formatMessage({ id: row.documentType }) : '',
          row.analysisRequestDate?.split('T')[0],
          row.financialsCompleteDate?.split('T')[0],
          row.nonFinancialsCompleteDate?.split('T')[0],
          row.analysisCompleteDate?.split('T')[0],
          row.completionDay,
          row.financialScore ? row.financialScore.toFixed(2) : '',
        ]}
      />
    </BootstrapDataTable>
  );
};

export default KpiAnalyzesTable;
