import React, { useState, useEffect } from 'react';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Card,
  CardBody,
  CardHeader,
} from 'reactstrap';
import { useAuth } from 'react-admin-base';
import { SingleFilePicker } from 'react-admin-base-bootstrap';

import { FormattedMessage } from 'react-intl';
import { useFormSubmit, CustomForm } from '../../../src/Components/CustomForm';
import { useNavigate } from 'react-router-dom';
import {
  AsyncPaginateInput,
  CategorySelectInput,
  InputField,
} from '../../Components/Input';
import { checkFileIsPdf } from '../../Util/checkFileIsPdf';
import {
  PrevAnalysesInfo,
  findExistingAnalyses,
} from '../../Common/PrevAnalysesInfo';

const conditionForFile = (optionsState, index) => {
  return (
    optionsState.documents[index].year &&
    (!optionsState.documents[index].isExistingAnalysis ||
      !findExistingAnalyses(
        optionsState.analyses,
        optionsState.documents[index].year,
        optionsState.documents[index].type,
        index
      ))
  );
};

export function AnalysisAktif({ userType, customer, sector }) {
  const [api] = useAuth();

  const [id, setId] = useState('');

  const navigate = useNavigate();

  const defaultNewState = {
    sectorCode: sector ? sector : '',
    analyses: [],
    analysisDetail: {
      analysisCustomerId: customer ? customer : null,
    },
    documents: [
      {
        financialFile: null,
        type: '9',
        useAsMain: false,
        pgnum_first: '',
        pgnum_last: '',
        documentLanguage: 'Turkish',
        year: '',
        isExistingAnalysis: false,
      },
      {
        financialFile: null,
        type: '9',
        useAsMain: true,
        pgnum_first: '',
        pgnum_last: '',
        documentLanguage: 'Turkish',
        year: '',
        isExistingAnalysis: false,
      },
      {
        financialFile: null,
        type: '17',
        useAsMain: false,
        pgnum_first: '',
        pgnum_last: '',
        documentLanguage: 'Turkish',
        year: '',
        isExistingAnalysis: false,
      },
      {
        financialFile: null,
        type: '3',
        useAsMain: false,
        pgnum_first: '',
        pgnum_last: '',
        documentLanguage: 'Turkish',
        year: '',
        isExistingAnalysis: false,
      },
    ],
  };

  const onSubmit = async () => {
    const newDocuments = optionsState.documents.filter(
      (d) => d.financialFile !== null || d.isExistingAnalysis
    );

    const newState = { ...optionsState, ['documents']: newDocuments };

    const response = await api.tokenized.post('/statement', {
      sectorCode: newState.sectorCode.code,
      companyAnalysisType: newState.companyAnalysisType,
      analysisDetail: {
        analysisCustomerId: newState.analysisDetail.analysisCustomerId.value,
      },
      documents: newState.documents.map((a) => {
        if (a.isExistingAnalysis) {
          const ob = a.isExistingAnalysis;
          return {
            documentId: ob.documentId,
            fileExtension: 'jsr',
            type: ob.providerType,
            useAsMain: a.useAsMain,
            pageNums: null,
            documentLanguage: 'Turkish',
            month: ob.statementMonth,
            year: ob.statementYear,
            isExistingAnalysis: true,
          };
        }
        return {
          documentId: a.financialFile.id,
          fileExtension: a.financialFile.name.match(/\.(....?)$/)[1], // . olacaksa [1] kismi [0] olacak
          type: +a.type,
          useAsMain: a.useAsMain,
          pageNums:
            !a.pgnum_first || !a.pgnum_last
              ? null
              : a.pgnum_first + '-' + a.pgnum_last, //"3-5",

          documentLanguage: a.documentLanguage || '',
          month: a.year.split('-')[1],
          year: a.year.split('-')[0],
        };
      }),
    });

    setId(response?.data.requestModel.resultId);
  };

  const isAdmin = userType >= 256;

  const validateOptions = (values) => {
    const error = {};
    if (!values.sectorCode) {
      error.sectorCode = 'REQUIRED';
    }

    if (!values.analysisDetail.analysisCustomerId) {
      error.analysisCustomerId = 'REQUIRED';
    }

    values.documents.forEach((v, index) => {
      error[index] = {};

      if (v.financialFile && !v.year) {
        error[index]['year'] = 'REQUIRED';
      }

      if (
        (v.type === '9' || v.type === '17') &&
        (!v.pgnum_first || !v.pgnum_last) &&
        v.financialFile &&
        checkFileIsPdf(v.financialFile) &&
        !v.isExistingAnalysis
      ) {
        error[index]['number'] = 'REQUIRED';
      }
    });

    if (
      !values.documents[1].financialFile &&
      !values.documents[1].isExistingAnalysis
    ) {
      error[1]['financialFile'] = 'REQUIRED';
    }
    if (
      !values.documents[0].financialFile &&
      !isAdmin &&
      !values.documents[0].isExistingAnalysis
    ) {
      error[0]['financialFile'] = 'REQUIRED';
    }

    return error;
  };

  const {
    loading,
    newSave,
    errors,
    optionsState,
    handleChangeDocuments,
    handleFilePicker,
    handleChangeSector,
    setError,
    handleFieldsValue,
  } = useFormSubmit(onSubmit, validateOptions, defaultNewState);

  const goPage = () => {
    navigate('/analysis/' + window.btoa(id) + '/edit');
    document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  if (id) {
    setTimeout(() => {
      goPage();
    }, 2000);
  }

  useEffect(() => {
    const aTags = document.getElementsByClassName(
      'btn btn-sm btn-outline-primary'
    );
    for (var i = 0; i < aTags.length; i++) {
      if (aTags[i].parentElement.lastChild) {
        aTags[i].parentElement.lastChild.style.display = 'none';
      }
      aTags[i].textContent = aTags[i]?.textContent?.substring(0, 8) + '...';
    }
  });

  const handleChangeAnalysisCustomerId = (e) => {
    if (e) {
      handleFieldsValue(e, 'analysisDetail');
      if (e.sector) {
        handleChangeSector(e.sector, 'sectorCode');
      } else {
        handleChangeSector(null, 'sectorCode');
      }
    } else {
      handleFieldsValue(
        { target: { name: 'analysisCustomerId', value: null } },
        'analysisDetail'
      );
      handleChangeSector(null, 'sectorCode');
    }
  };

  React.useEffect(() => {
    const fetchCustomerAnalyses = async () => {
      const result = await api.tokenized.get(
        `/customer/get/analyzes/${optionsState.analysisDetail.analysisCustomerId.value}`
      );
      if (result.status === 200) {
        handleChangeSector(result.data, 'analyses');
      } else {
        handleChangeSector([], 'analyses');
      }
    };

    if (optionsState.analysisDetail?.analysisCustomerId?.value)
      fetchCustomerAnalyses();
    else handleChangeSector([], 'analyses');
  }, [optionsState.analysisDetail?.analysisCustomerId?.value]);

  React.useEffect(() => {
    handleChangeDocuments(
      {
        target: {
          name: 'isExistingAnalysis',
          value:
            findExistingAnalyses(
              optionsState.analyses,
              optionsState.documents[0].year,
              optionsState.documents[0].type,
              0
            ) && optionsState.analysisDetail?.analysisCustomerId?.value
              ? findExistingAnalyses(
                  optionsState.analyses,
                  optionsState.documents[0].year,
                  optionsState.documents[0].type,
                  0
                )
              : false,
        },
      },
      0
    );
  }, [
    optionsState.analyses,
    optionsState.documents[0].year,
    optionsState.documents[0].type,
  ]);
  React.useEffect(() => {
    handleChangeDocuments(
      {
        target: {
          name: 'isExistingAnalysis',
          value:
            findExistingAnalyses(
              optionsState.analyses,
              optionsState.documents[1].year,
              optionsState.documents[1].type,
              1
            ) && optionsState.analysisDetail?.analysisCustomerId?.value
              ? findExistingAnalyses(
                  optionsState.analyses,
                  optionsState.documents[1].year,
                  optionsState.documents[1].type,
                  1
                )
              : false,
        },
      },
      1
    );
  }, [
    optionsState.analyses,
    optionsState.documents[1].year,
    optionsState.documents[1].type,
  ]);
  React.useEffect(() => {
    handleChangeDocuments(
      {
        target: {
          name: 'isExistingAnalysis',
          value:
            findExistingAnalyses(
              optionsState.analyses,
              optionsState.documents[2].year,
              optionsState.documents[2].type,
              2
            ) && optionsState.analysisDetail?.analysisCustomerId?.value
              ? findExistingAnalyses(
                  optionsState.analyses,
                  optionsState.documents[2].year,
                  optionsState.documents[2].type,
                  2
                )
              : false,
        },
      },
      2
    );
  }, [
    optionsState.analyses,
    optionsState.documents[2].year,
    optionsState.documents[2].type,
  ]);
  React.useEffect(() => {
    handleChangeDocuments(
      {
        target: {
          name: 'isExistingAnalysis',
          value:
            findExistingAnalyses(
              optionsState.analyses,
              optionsState.documents[3].year,
              optionsState.documents[3].type,
              3
            ) && optionsState.analysisDetail?.analysisCustomerId?.value
              ? findExistingAnalyses(
                  optionsState.analyses,
                  optionsState.documents[3].year,
                  optionsState.documents[3].type,
                  3
                )
              : false,
        },
      },
      3
    );
  }, [
    optionsState.analyses,
    optionsState.documents[3].year,
    optionsState.documents[3].type,
  ]);
  console.log('optionsState', optionsState);

  return (
    <CustomForm
      submit={newSave}
      errors={errors}
      loading={loading}
      done={id}
      id="REDIRECT_AKTIF"
      setError={setError}
    >
      {!customer && (
        <FormGroup className={'mt-3'}>
          <AsyncPaginateInput
            id="analysisCustomerId"
            name="analysisCustomerId"
            value={customer || optionsState.analysisDetail.analysisCustomerId}
            onChange={handleChangeAnalysisCustomerId}
            api={api}
            validation={errors.validation}
            label=""
            disabled={customer ? true : false}
          >
            <Button
              style={{ marginLeft: '1em' }}
              size="sm"
              color="success"
              className="fas fa-plus"
              onClick={() => navigate('/customer/add')}
            />
          </AsyncPaginateInput>
        </FormGroup>
      )}
      {!sector && (
        <FormGroup className={'mt-3'}>
          <Row>
            <Col>
              <CategorySelectInput
                id="SECTOR_TYPE"
                validation={errors.validation}
                name={'sectorCode'}
                value={sector || optionsState.sectorCode}
                onChange={(e) => {
                  handleChangeSector(e, 'sectorCode');
                }}
                tooltip={<FormattedMessage id="SECTOR_TYPE_INFO" />}
                disabled={sector ? true : false}
              />
            </Col>
          </Row>
        </FormGroup>
      )}

      {!isAdmin && (
        <FormGroup>
          <Row>
            <Col sm="4">
              <Label>
                <FormattedMessage id="DOCUMENT_TYPE" />
              </Label>
              <Input
                type="select"
                onChange={(e) => {
                  const { value, name } = e.target;
                  const newElement =
                    value === '1'
                      ? { target: { name: 'type', value: '7' } }
                      : e;
                  handleChangeDocuments(e, 0);
                  handleChangeDocuments(e, 1);
                  handleChangeDocuments(newElement, 2);
                }}
                value={optionsState.documents[0].type}
                name="type"
              >
                {[
                  {
                    name: <FormattedMessage id="Bağımsız Denetim Raporu" />,
                    type: '9',
                  },
                  {
                    name: (
                      <FormattedMessage id="Kurumlar Vergisi Beyannamesi" />
                    ),
                    type: '1',
                  },
                ].map((o, i) => (
                  <option value={o.type} key={i}>
                    {o.name}
                  </option>
                ))}
              </Input>
            </Col>
          </Row>
        </FormGroup>
      )}
      <FormGroup className="!mb-0">
        <Row>
          <Col>
            <Card>
              <CardHeader className="p-2">
                <h6
                  style={{
                    display: 'flex',
                    fontSize: '16px',
                    color: '343a40',
                  }}
                >
                  <FormattedMessage id="PREVIOUS_YEAR" />{' '}
                  {optionsState.documents[0].year} (
                  <div style={{ color: !isAdmin ? 'red' : 'blue' }}>
                    <FormattedMessage id={!isAdmin ? 'REQUIRE' : 'OPTIONAL'} />
                  </div>
                  )
                </h6>
              </CardHeader>
              <CardBody>
                <Row className="mb-2">
                  <Col className="mb-2">
                    <InputField
                      name="year"
                      value={optionsState.documents[0].year}
                      type="month"
                      onChange={(e) => {
                        handleChangeDocuments(e, 0);
                      }}
                      max="9999-12"
                    />
                    {errors.validation &&
                      errors.validation[0] &&
                      errors.validation[0].year && (
                        <p
                          style={{
                            color: 'red',
                            opacity: 0.6,
                            position: 'absolute',
                            fontSize: '12px',
                          }}
                        >
                          <FormattedMessage id="Year" />{' '}
                          <FormattedMessage id={errors.validation[0].year} />
                        </p>
                      )}
                  </Col>
                </Row>
                {isAdmin && (
                  <>
                    <Row className="mb-2">
                      <Col sm="10">
                        {' '}
                        <FormattedMessage id={'Bağımsız Denetim Raporu'} />
                      </Col>
                      <Col>
                        <InputField
                          type="checkbox"
                          name="type"
                          value="9"
                          checked={optionsState.documents[0].type === '9'}
                          onChange={(e) => {
                            handleChangeDocuments(e, 0);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col sm="10">
                        <FormattedMessage id={'Kurumlar Vergisi Beyannamesi'} />
                      </Col>
                      <Col>
                        <InputField
                          type="checkbox"
                          name="type"
                          value="1"
                          checked={optionsState.documents[0].type === '1'}
                          onChange={(e) => {
                            handleChangeDocuments(e, 0);
                          }}
                        />
                      </Col>
                    </Row>
                  </>
                )}

                {conditionForFile(optionsState, 0) && (
                  <>
                    <Row className="mb-2">
                      <SingleFilePicker
                        accepts=".pdf,.xlsx,.png,.jpg"
                        value={optionsState.documents[0]?.financialFile}
                        onChange={(a) => {
                          if (a === null)
                            handleChangeDocuments(
                              {
                                target: {
                                  name: 'isExistingAnalysis',
                                  value: findExistingAnalyses(
                                    optionsState.analyses,
                                    optionsState.documents[0].year,
                                    optionsState.documents[0].type,
                                    0
                                  ),
                                },
                              },
                              0
                            );
                          handleFilePicker(a, 0);
                        }}
                        disabled={!optionsState.documents[0].year}
                      />
                    </Row>
                    <Row>
                      {errors.validation &&
                        errors.validation[0] &&
                        errors.validation[0].financialFile && (
                          <p
                            style={{
                              color: 'red',
                              opacity: 0.6,
                              position: 'absolute',
                              fontSize: '12px',
                            }}
                          >
                            <FormattedMessage id="financialFile" />{' '}
                            <FormattedMessage
                              id={errors.validation[0].financialFile}
                            />
                          </p>
                        )}
                    </Row>
                    {optionsState.documents[0].type === '9' &&
                      optionsState.documents[0].financialFile && (
                        <>
                          <Row className="mt-4">
                            {' '}
                            <Label>
                              <FormattedMessage id="DOCUMENT_LANGUAGE" />
                            </Label>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <FormattedMessage id="BAĞIMSIZ_DENETİM_RAPOR_TURKISH" />
                              <Input
                                style={{ marginLeft: '1em' }}
                                name="documentLanguage"
                                type="checkbox"
                                value="Turkish"
                                checked={
                                  optionsState.documents[0].documentLanguage ===
                                  'Turkish'
                                }
                                onChange={(e) => handleChangeDocuments(e, 0)}
                              />
                            </Col>

                            <Col sm={6}>
                              <FormattedMessage id="BAĞIMSIZ_DENETİM_RAPOR_ENGLISH" />
                              <Input
                                style={{ marginLeft: '1em' }}
                                name="documentLanguage"
                                type="checkbox"
                                value="English"
                                checked={
                                  optionsState.documents[0].documentLanguage ===
                                  'English'
                                }
                                onChange={(e) => handleChangeDocuments(e, 0)}
                              />
                            </Col>
                          </Row>
                          {checkFileIsPdf(
                            optionsState.documents[0].financialFile
                          ) && (
                            <>
                              <Row className="mt-4">
                                <Label>
                                  <FormattedMessage id="FINANCIAL_FILE_PAGE_NUMBERS" />
                                </Label>
                              </Row>
                              <Row className="mb-2">
                                <Col>
                                  <Label>
                                    <FormattedMessage id="START" />
                                  </Label>
                                </Col>
                                <Col>
                                  <Input
                                    bsSize="sm"
                                    name="pgnum_first"
                                    value={
                                      optionsState.documents[0].pgnum_first
                                    }
                                    onChange={(e) =>
                                      handleChangeDocuments(e, 0)
                                    }
                                    type="number"
                                    onKeyPress={(event) => {
                                      if (
                                        event.key === 'e' ||
                                        event.key === '.' ||
                                        event.key === '-' ||
                                        event.key === ',' ||
                                        event.key === '+'
                                      ) {
                                        event.preventDefault();
                                      }
                                    }}
                                    onPaste={(event) => {
                                      let pastedValue =
                                        event.clipboardData.getData('text');
                                      if (
                                        pastedValue?.includes('e') ||
                                        pastedValue?.includes('.') ||
                                        pastedValue?.includes('-') ||
                                        pastedValue?.includes(',') ||
                                        pastedValue?.includes('+')
                                      ) {
                                        event.preventDefault();
                                      }
                                    }}
                                    min={0}
                                    max={300}
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-2">
                                <Col>
                                  <Label>
                                    <FormattedMessage id="END" />
                                  </Label>
                                </Col>

                                <Col>
                                  <Input
                                    bsSize="sm"
                                    name="pgnum_last"
                                    value={optionsState.documents[0].pgnum_last}
                                    onChange={(e) =>
                                      handleChangeDocuments(e, 0)
                                    }
                                    type="number"
                                    onKeyPress={(event) => {
                                      if (
                                        event.key === 'e' ||
                                        event.key === '.' ||
                                        event.key === '-' ||
                                        event.key === ',' ||
                                        event.key === '+'
                                      ) {
                                        event.preventDefault();
                                      }
                                    }}
                                    onPaste={(event) => {
                                      let pastedValue =
                                        event.clipboardData.getData('text');
                                      if (
                                        pastedValue?.includes('e') ||
                                        pastedValue?.includes('.') ||
                                        pastedValue?.includes('-') ||
                                        pastedValue?.includes(',') ||
                                        pastedValue?.includes('+')
                                      ) {
                                        event.preventDefault();
                                      }
                                    }}
                                    min={0}
                                    max={300}
                                  />
                                </Col>
                              </Row>
                            </>
                          )}
                          {errors.validation &&
                            errors.validation[0] &&
                            errors.validation[0].number && (
                              <p
                                style={{
                                  color: 'red',
                                  opacity: 0.6,
                                  fontSize: '12px',
                                }}
                              >
                                <FormattedMessage id="FINANCIAL_FILE_PAGE_NUMBERS" />{' '}
                                <FormattedMessage
                                  id={errors.validation[0].number}
                                />
                              </p>
                            )}
                        </>
                      )}
                  </>
                )}

                {findExistingAnalyses(
                  optionsState.analyses,
                  optionsState.documents[0].year,
                  optionsState.documents[0].type,
                  0
                ) && (
                  <Row>
                    <PrevAnalysesInfo
                      filteredData={findExistingAnalyses(
                        optionsState.analyses,
                        optionsState.documents[0].year,
                        optionsState.documents[0].type,
                        0
                      )}
                      handleChangeDocuments={(e) =>
                        handleChangeDocuments(
                          {
                            target: {
                              name: 'isExistingAnalysis',
                              value: e,
                            },
                          },
                          0
                        )
                      }
                      existingFile={
                        optionsState.documents[0].isExistingAnalysis
                      }
                    />
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card>
              <CardHeader className="p-2">
                <h6
                  style={{
                    display: 'flex',
                    fontSize: '16px',
                    color: '343a40',
                  }}
                >
                  <FormattedMessage id="STATEMENT_YEAR" />{' '}
                  {optionsState.documents[1].year} (
                  <div style={{ color: 'red' }}>
                    <FormattedMessage id="REQUIRE" />
                  </div>
                  )
                </h6>
              </CardHeader>
              <CardBody>
                <Row className="mb-2">
                  <Col className="mb-2">
                    <InputField
                      name="year"
                      value={optionsState.documents[1].year}
                      type="month"
                      onChange={(e) => handleChangeDocuments(e, 1)}
                      max="9999-12"
                    />
                    {errors.validation &&
                      errors.validation[1] &&
                      errors.validation[1].year && (
                        <p
                          style={{
                            color: 'red',
                            opacity: 0.6,
                            position: 'absolute',
                            fontSize: '12px',
                          }}
                        >
                          <FormattedMessage id="Year" />{' '}
                          <FormattedMessage id={errors.validation[1].year} />
                        </p>
                      )}
                  </Col>
                </Row>
                {isAdmin && (
                  <>
                    <Row className="mb-2">
                      <Col sm="10">
                        {' '}
                        <FormattedMessage id={'Bağımsız Denetim Raporu'} />
                      </Col>
                      <Col>
                        <InputField
                          type="checkbox"
                          name="type"
                          value="9"
                          checked={optionsState.documents[1].type === '9'}
                          onChange={(e) => {
                            handleChangeDocuments(e, 1);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col sm="10">
                        {' '}
                        <FormattedMessage id={'Kurumlar Vergisi Beyannamesi'} />
                      </Col>
                      <Col>
                        {' '}
                        <InputField
                          type="checkbox"
                          name="type"
                          value="1"
                          checked={optionsState.documents[1].type === '1'}
                          onChange={(e) => {
                            handleChangeDocuments(e, 1);
                          }}
                        />
                      </Col>
                    </Row>
                  </>
                )}
                {conditionForFile(optionsState, 1) && (
                  <>
                    <Row className="mb-2">
                      <SingleFilePicker
                        accepts=".pdf,.xlsx,.png,.jpg"
                        value={optionsState.documents[1]?.financialFile}
                        onChange={(a) => {
                          if (a === null)
                            handleChangeDocuments(
                              {
                                target: {
                                  name: 'isExistingAnalysis',
                                  value: findExistingAnalyses(
                                    optionsState.analyses,
                                    optionsState.documents[1].year,
                                    optionsState.documents[1].type,
                                    1
                                  ),
                                },
                              },
                              1
                            );

                          handleFilePicker(a, 1);
                        }}
                        disabled={!optionsState.documents[1].year}
                      />
                    </Row>
                    <Row>
                      {errors.validation &&
                        errors.validation[1] &&
                        errors.validation[1].financialFile && (
                          <p
                            style={{
                              color: 'red',
                              opacity: 0.6,
                              position: 'absolute',
                              fontSize: '12px',
                            }}
                          >
                            <FormattedMessage id="financialFile" />{' '}
                            <FormattedMessage
                              id={errors.validation[1]?.financialFile}
                            />
                          </p>
                        )}
                    </Row>
                    {optionsState.documents[1]?.type === '9' &&
                      optionsState.documents[1].financialFile && (
                        <>
                          <Row className="mt-4">
                            {' '}
                            <Label>
                              <FormattedMessage id="DOCUMENT_LANGUAGE" />
                            </Label>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <FormattedMessage id="BAĞIMSIZ_DENETİM_RAPOR_TURKISH" />
                              <Input
                                style={{ marginLeft: '1em' }}
                                name="documentLanguage"
                                type="checkbox"
                                value="Turkish"
                                checked={
                                  optionsState.documents[1].documentLanguage ===
                                  'Turkish'
                                }
                                onChange={(e) => handleChangeDocuments(e, 1)}
                              />
                            </Col>

                            <Col sm={6}>
                              <FormattedMessage id="BAĞIMSIZ_DENETİM_RAPOR_ENGLISH" />
                              <Input
                                style={{ marginLeft: '1em' }}
                                name="documentLanguage"
                                type="checkbox"
                                value="English"
                                checked={
                                  optionsState.documents[1].documentLanguage ===
                                  'English'
                                }
                                onChange={(e) => handleChangeDocuments(e, 1)}
                              />
                            </Col>
                          </Row>
                          {checkFileIsPdf(
                            optionsState.documents[1].financialFile
                          ) && (
                            <>
                              <Row className="mt-4">
                                <Label>
                                  <FormattedMessage id="FINANCIAL_FILE_PAGE_NUMBERS" />
                                </Label>
                              </Row>
                              <Row className="mb-2">
                                <Col>
                                  <FormattedMessage id="START" />
                                </Col>
                                <Col>
                                  <Input
                                    bsSize="sm"
                                    name="pgnum_first"
                                    value={
                                      optionsState.documents[1].pgnum_first
                                    }
                                    onChange={(e) =>
                                      handleChangeDocuments(e, 1)
                                    }
                                    type="number"
                                    onKeyPress={(event) => {
                                      if (
                                        event.key === 'e' ||
                                        event.key === '.' ||
                                        event.key === '-' ||
                                        event.key === ',' ||
                                        event.key === '+'
                                      ) {
                                        event.preventDefault();
                                      }
                                    }}
                                    onPaste={(event) => {
                                      let pastedValue =
                                        event.clipboardData.getData('text');
                                      if (
                                        pastedValue?.includes('e') ||
                                        pastedValue?.includes('.') ||
                                        pastedValue?.includes('-') ||
                                        pastedValue?.includes(',') ||
                                        pastedValue?.includes('+')
                                      ) {
                                        event.preventDefault();
                                      }
                                    }}
                                    min={0}
                                    max={300}
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-2">
                                <Col>
                                  <FormattedMessage id="END" />
                                </Col>

                                <Col>
                                  <Input
                                    bsSize="sm"
                                    name="pgnum_last"
                                    value={optionsState.documents[1].pgnum_last}
                                    onChange={(e) =>
                                      handleChangeDocuments(e, 1)
                                    }
                                    type="number"
                                    onKeyPress={(event) => {
                                      if (
                                        event.key === 'e' ||
                                        event.key === '.' ||
                                        event.key === '-' ||
                                        event.key === ',' ||
                                        event.key === '+'
                                      ) {
                                        event.preventDefault();
                                      }
                                    }}
                                    onPaste={(event) => {
                                      let pastedValue =
                                        event.clipboardData.getData('text');
                                      if (
                                        pastedValue?.includes('e') ||
                                        pastedValue?.includes('.') ||
                                        pastedValue?.includes('-') ||
                                        pastedValue?.includes(',') ||
                                        pastedValue?.includes('+')
                                      ) {
                                        event.preventDefault();
                                      }
                                    }}
                                    min={0}
                                    max={300}
                                  />
                                </Col>
                              </Row>
                              {errors.validation &&
                                errors.validation[1] &&
                                errors.validation[1].number && (
                                  <p
                                    style={{
                                      color: 'red',
                                      opacity: 0.6,
                                      fontSize: '12px',
                                    }}
                                  >
                                    <FormattedMessage id="FINANCIAL_FILE_PAGE_NUMBERS" />{' '}
                                    <FormattedMessage
                                      id={errors.validation[1].number}
                                    />
                                  </p>
                                )}
                            </>
                          )}
                        </>
                      )}
                  </>
                )}

                {findExistingAnalyses(
                  optionsState.analyses,
                  optionsState.documents[1].year,
                  optionsState.documents[1].type,
                  1
                ) && (
                  <Row>
                    <PrevAnalysesInfo
                      filteredData={findExistingAnalyses(
                        optionsState.analyses,
                        optionsState.documents[1].year,
                        optionsState.documents[1].type,
                        1
                      )}
                      handleChangeDocuments={(e) =>
                        handleChangeDocuments(
                          {
                            target: {
                              name: 'isExistingAnalysis',
                              value: e,
                            },
                          },
                          1
                        )
                      }
                      existingFile={
                        optionsState.documents[1].isExistingAnalysis
                      }
                    />
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card>
              <CardHeader className="p-2">
                <h6
                  style={{
                    display: 'flex',
                    fontSize: '16px',
                    color: '343a40',
                  }}
                >
                  <FormattedMessage id="MIDTERM" />{' '}
                  {optionsState.documents[2].year} (
                  <div style={{ color: 'blue' }}>
                    <FormattedMessage id="OPTIONAL" />
                  </div>
                  )
                </h6>
              </CardHeader>
              <CardBody>
                <Row className="mb-2">
                  <Col className="mb-2" style={{ marginLeft: '-12px' }}>
                    <InputField
                      name="year"
                      value={optionsState.documents[2].year}
                      type="month"
                      onChange={(e) => {
                        handleChangeDocuments(e, 2);
                        handleChangeDocuments(e, 3);
                      }}
                      max="9999-12"
                    />
                    {errors.validation &&
                      errors.validation[2] &&
                      errors.validation[2].year && (
                        <p
                          style={{
                            color: 'red',
                            opacity: 0.6,
                            position: 'absolute',
                            fontSize: '12px',
                          }}
                        >
                          <FormattedMessage id="Year" />{' '}
                          <FormattedMessage id={errors.validation[2].year} />
                        </p>
                      )}
                  </Col>
                </Row>
                {isAdmin && (
                  <>
                    <Row className="mb-2">
                      <Col sm="10" style={{ padding: 0 }}>
                        {' '}
                        <FormattedMessage id={'Bağımsız Denetim Raporu'} />
                      </Col>
                      <Col style={{ padding: 0 }}>
                        <InputField
                          type="checkbox"
                          name="type"
                          value="17"
                          checked={optionsState.documents[2].type === '17'}
                          onChange={(e) => {
                            handleFilePicker(null, 3);
                            handleChangeDocuments(e, 2);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col sm="10" style={{ padding: 0 }}>
                        <FormattedMessage id={'Mizan'} /> -{' '}
                        <FormattedMessage id="MIDTERM" />
                      </Col>
                      <Col style={{ padding: 0 }}>
                        <InputField
                          type="checkbox"
                          name="type"
                          value="7"
                          checked={optionsState.documents[2].type === '7'}
                          onChange={(e) => {
                            handleFilePicker(null, 3);
                            handleChangeDocuments(e, 2);
                          }}
                        />
                      </Col>
                    </Row>
                  </>
                )}
                <Row className="mb-2">
                  {optionsState.documents[2].type !== '7' ? (
                    <>
                      <SingleFilePicker
                        className="p-0"
                        accepts=".pdf,.xlsx,.png,.jpg"
                        value={optionsState.documents[2]?.financialFile}
                        onChange={(a) => {
                          handleFilePicker(a, 2);
                        }}
                        disabled={!optionsState.documents[2].year}
                      />
                    </>
                  ) : (
                    <>
                      <Col className="mb-2">
                        <Row className="mb-2">
                          {' '}
                          <FormattedMessage id={'Mizan'} />
                        </Row>
                        <Row style={{ padding: 0 }}>
                          <SingleFilePicker
                            className="p-0"
                            accepts=".pdf,.xlsx,.png,.jpg"
                            value={optionsState.documents[2]?.financialFile}
                            onChange={(a) => {
                              handleFilePicker(a, 2);
                            }}
                            disabled={!optionsState.documents[2].year}
                          />
                        </Row>
                      </Col>

                      <Col className="mb-2">
                        <Row className="mb-2">
                          <FormattedMessage id="ARA_DONEM_KVB" />
                        </Row>
                        <Row>
                          <SingleFilePicker
                            className="p-0"
                            accepts=".pdf,.xlsx,.png,.jpg"
                            value={optionsState.documents[3]?.financialFile}
                            onChange={(a) => {
                              handleFilePicker(a, 3);
                            }}
                            disabled={!optionsState.documents[3].year}
                          />
                        </Row>
                      </Col>
                    </>
                  )}
                </Row>
                {errors.validation &&
                  errors.validation[2] &&
                  errors.validation[2].financialFile && (
                    <p
                      style={{
                        color: 'red',
                        opacity: 0.6,
                        marginLeft: '-12px',
                        position: 'absolute',
                        fontSize: '12px',
                      }}
                    >
                      <FormattedMessage id="financialFile" />{' '}
                      <FormattedMessage
                        id={errors.validation[2]?.financialFile}
                      />
                    </p>
                  )}

                {(optionsState.documents[2].type === '9' ||
                  optionsState.documents[2].type === '17') &&
                  optionsState.documents[2].financialFile && (
                    <>
                      <Row className="mt-4">
                        {' '}
                        <Label>
                          <FormattedMessage id="DOCUMENT_LANGUAGE" />
                        </Label>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <FormattedMessage id="BAĞIMSIZ_DENETİM_RAPOR_TURKISH" />
                          <Input
                            style={{ marginLeft: '1em' }}
                            name="documentLanguage"
                            type="checkbox"
                            value="Turkish"
                            checked={
                              optionsState.documents[2].documentLanguage ===
                              'Turkish'
                            }
                            onChange={(e) => handleChangeDocuments(e, 2)}
                          />
                        </Col>

                        <Col sm={6}>
                          <FormattedMessage id="BAĞIMSIZ_DENETİM_RAPOR_ENGLISH" />
                          <Input
                            style={{ marginLeft: '1em' }}
                            name="documentLanguage"
                            type="checkbox"
                            value="English"
                            checked={
                              optionsState.documents[2].documentLanguage ===
                              'English'
                            }
                            onChange={(e) => handleChangeDocuments(e, 2)}
                          />
                        </Col>
                      </Row>
                      {checkFileIsPdf(
                        optionsState.documents[2].financialFile
                      ) && (
                        <>
                          <Row className="mt-4">
                            <Label>
                              <FormattedMessage id="FINANCIAL_FILE_PAGE_NUMBERS" />
                            </Label>
                          </Row>
                          <Row className="mb-2">
                            <Col>
                              <Label>
                                <FormattedMessage id="START" />
                              </Label>
                            </Col>
                            <Col>
                              <Input
                                bsSize="sm"
                                name="pgnum_first"
                                value={optionsState.documents[2].pgnum_first}
                                onChange={(e) => handleChangeDocuments(e, 2)}
                                type="number"
                                onKeyPress={(event) => {
                                  if (
                                    event.key === 'e' ||
                                    event.key === '.' ||
                                    event.key === '-' ||
                                    event.key === ',' ||
                                    event.key === '+'
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                onPaste={(event) => {
                                  let pastedValue =
                                    event.clipboardData.getData('text');
                                  if (
                                    pastedValue?.includes('e') ||
                                    pastedValue?.includes('.') ||
                                    pastedValue?.includes('-') ||
                                    pastedValue?.includes(',') ||
                                    pastedValue?.includes('+')
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                min={0}
                                max={300}
                              />
                            </Col>
                          </Row>
                          <Row className="mb-2">
                            <Col>
                              <Label>
                                <FormattedMessage id="END" />
                              </Label>
                            </Col>

                            <Col>
                              <Input
                                bsSize="sm"
                                name="pgnum_last"
                                value={optionsState.documents[2].pgnum_last}
                                onChange={(e) => handleChangeDocuments(e, 2)}
                                type="number"
                                onKeyPress={(event) => {
                                  if (
                                    event.key === 'e' ||
                                    event.key === '.' ||
                                    event.key === '-' ||
                                    event.key === ',' ||
                                    event.key === '+'
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                onPaste={(event) => {
                                  let pastedValue =
                                    event.clipboardData.getData('text');
                                  if (
                                    pastedValue?.includes('e') ||
                                    pastedValue?.includes('.') ||
                                    pastedValue?.includes('-') ||
                                    pastedValue?.includes(',') ||
                                    pastedValue?.includes('+')
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                min={0}
                                max={300}
                              />
                            </Col>
                          </Row>
                        </>
                      )}
                      {errors.validation &&
                        errors.validation[2] &&
                        errors.validation[2].number && (
                          <p
                            style={{
                              color: 'red',
                              opacity: 0.6,
                              fontSize: '12px',
                            }}
                          >
                            <FormattedMessage id="FINANCIAL_FILE_PAGE_NUMBERS" />{' '}
                            <FormattedMessage
                              id={errors.validation[2].number}
                            />
                          </p>
                        )}
                    </>
                  )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </FormGroup>
    </CustomForm>
  );
}
