export const icons = {
  delete: 'fas fa-trash',
  edit: 'fa fa-pencil-alt',
  add: 'fas fa-plus',
  search: 'fas fa-search',
  save: 'fas fa-save',
  startAnalysis: 'fas fa-chart-pie',
  profile: 'fas fa-user',
  download: 'fas fa-download',
  filter: 'fas fa-filter',
  pdf: 'fa-regular fa-file-pdf',
  info: 'fas fa-info',
  eye: 'fas fa-eye',
};
