/*eslint no-extra-boolean-cast: ["error", {"enforceForLogicalOperands": false}]*/

import React, { useEffect } from 'react';
import { useApp } from 'react-admin-base';
import { LanguageSwitcher } from 'react-admin-base-bootstrap';
import { Col, Container, Row } from 'reactstrap';
import { useThemeOptions } from 'react-admin-base-nazox/lib/esm/ThemeOptions';
import Themes from 'react-admin-base-nazox/lib/esm/Themes';
import { Link } from 'react-router-dom';

//options içeriğini ayarla. artık dark light mod yok, login ekranı ve dashboard background farkı yok.

export default function Layout({ children }) {
  const app = useApp();
  const options = useThemeOptions();

  const logo = (app.logo || app.onlylogo) && (
    <Link to="https://jetscoring.com/tr">
      <img src={app.logo || app.onlylogo} alt={app.name} />
    </Link>
  );

  // useEffect(() => {
  //   document.body.className =
  //     "auth-body-bg" + (options.theme ? " fixed-theme-" + options.theme : "");
  // }, [options]);

  return (
    <Themes>
      <div>
        <Container fluid className="p-0">
          <Row
            className={
              options.noLoginBanner ? 'g-0 justify-content-center' : 'g-0'
            }
          >
            <Col lg={!options.noLoginBanner ? '4' : '5'}>
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <div className="col-lg-9">
                      <div>
                        <div className="text-center">
                          {logo}
                          {!app.logo && (
                            <h4 className="font-size-18 mt-4">{app.name}</h4>
                          )}
                        </div>
                        {children}
                        <div className="mt-3">
                          <LanguageSwitcher />
                        </div>
                      </div>
                    </div>
                  </Row>
                </div>
              </div>
            </Col>
            {!options.noLoginBanner && (
              <Col lg="8">
                <div className="bg-overlay" />
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </Themes>
  );
}
