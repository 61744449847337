import React, { useState } from 'react';
import { Table, UncontrolledTooltip } from 'reactstrap';
import { Tab, Tabs } from 'react-bootstrap';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { DownloadFileButton } from '../Components/DownloadFileButton';
import { ExcelImportFromTable } from '../Components/DownloadExcelFromTable';
import { Link } from 'react-router-dom';

export const filterTitles = (titles) => {
  return titles.filter((r) => {
    const allZero = !r.values.some((e) => e.value !== 0);

    return (
      (r.category === 'sub-sub' || !allZero) &&
      r.title !== 'Income Statement-Summary' &&
      r.title !== 'Tangible Net Worth' &&
      r.title !== 'Working Capital'
    );
  });
};

export function FinansalTablolar({
  value,
  slug,
  companyCode,
  isConsolidated,
  activeStatus,
  currencyInfo,
}) {
  const [key4, setKey] = useState(slug + 0);
  const intl = useIntl();
  const text = activeStatus && activeStatus.status;
  const year = activeStatus && activeStatus.year;
  const difference =
    activeStatus && new Intl.NumberFormat('tr').format(activeStatus.difference);

  const excelProps = value.map((e, i) => {
    return { id: `module${i}-to-xls`, sheetName: e.name };
  });

  return (
    <div className={'mt-2'}>
      {/* should be reviev */}

      <div
        style={{
          justifyContent: 'space-between',
          display: 'flex',
        }}
      >
        <div>
          {currencyInfo && (
            <>
              <div style={{ fontWeight: 'bold' }}>
                *{currencyInfo.currencyText}
              </div>
              <div style={{ fontWeight: 'bold' }}>
                {<FormattedMessage id="CURRENCY_UNIT" />}:{' '}
                {currencyInfo.currencyUnit}
              </div>
            </>
          )}
        </div>

        <div className="d-flex" style={{ marginBottom: '1em' }}>
          {(companyCode === 'ta3meed' ||
            companyCode === 'aktifbank' ||
            companyCode === 'ta3meedbnpl' ||
            companyCode === 'param' ||
            companyCode === 'worqcompany' ||
            companyCode === 'tofas' ||
            companyCode === 'teb' ||
            companyCode === 'usulventures' ||
            companyCode === 'tbaydin' ||
            companyCode === 'tai') && (
            <Link
              to={
                companyCode !== 'ta3meed'
                  ? '/analysis/' + window.btoa(slug) + '/edit'
                  : '/analysis/' + window.btoa(slug) + '/edit/empty'
              }
            >
              <button className="btn btn-outline-primary">
                <FormattedMessage id="EDIT" />
                <i
                  className="fa fa-pencil-alt"
                  style={{ marginLeft: '5px' }}
                ></i>
              </button>
            </Link>
          )}
          {companyCode === 'ta3meedbnpl' && (
            <>
              <DownloadFileButton
                messageId={`${
                  companyCode === 'ta3meedbnpl' ? 'Uploaded ' : ''
                }${intl.formatMessage({
                  id: 'FINANCIAL_STATEMENT_DOWNLOAD',
                })}`}
                url={`bnpl/download/financialStatement/${slug}`}
                dname={'Financial Statement'}
                className={'btn btn-info btn btn-secondary'}
                style={{ marginLeft: '1em', marginRight: '1em' }}
                method="get"
              />
              <DownloadFileButton
                messageId={`Uploaded Bank Statement ${intl.formatMessage({
                  id: 'DOWNLOAD',
                })}`}
                url={`bnpl/download/bankStatement/${slug}`}
                dname={'Bank Statement'}
                className={'btn btn-info btn btn-secondary'}
                style={{ marginLeft: '1em' }}
                method="get"
              />
            </>
          )}

          <button
            onClick={() => ExcelImportFromTable(excelProps, intl)}
            className="font-xl btn btn-primary btn-block"
            type="button"
            style={{ marginLeft: '1em', marginRight: '1em' }}
          >
            <FormattedMessage id="TAB_4_FINANCIAL_TABLES" /> Excel{' '}
            <FormattedMessage id="DOWNLOAD" />
          </button>

          {(companyCode === 'aktifbank' ||
            companyCode === 'param' ||
            companyCode === 'teb') && (
            <>
              {!isConsolidated ? (
                companyCode === 'param' ? (
                  <></>
                ) : (
                  <DownloadFileButton
                    messageId={`                   Spread
 ${intl.formatMessage({ id: 'DOWNLOAD' })}`}
                    url={`analyze/spreads/${slug}`}
                    dname={'Spread'}
                    className={'btn btn-info btn btn-secondary'}
                    method="get"
                  />
                )
              ) : (
                <DownloadFileButton
                  messageId={<FormattedMessage id="CONSOLIDATION_DOWNLOAD" />}
                  url={`consolidation/${slug}`}
                  dname={intl.formatMessage({ id: 'CONSOLIDATION' })}
                  className={'btn btn-info btn btn-secondary'}
                  method="get"
                />
              )}
            </>
          )}

          {companyCode === 'tofas' && (
            <DownloadFileButton
              messageId={<FormattedMessage id="TOFAS_SCORING" />}
              url={`analyze/tofas/format/${slug}`}
              dname={'TOFAS Skorlama'}
              className={'btn btn-info btn btn-secondary'}
              method={'get'}
            />
          )}
        </div>
      </div>

      <Tabs activeKey={key4} onSelect={(key4) => setKey(key4)}>
        {value.map((row, i) => (
          <Tab eventKey={slug + i} title={row.name} key={i}>
            <Table
              id={'module' + i + '-to-xls'}
              responsive
              bordered
              key={i}
              size="sm"
            >
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="SUBTAB_4_1_TITLE1" />
                  </th>
                  {row.dataList &&
                    row.dataList[0] &&
                    row.dataList[0].titles &&
                    row.dataList[0].titles[0] &&
                    row.dataList[0].titles[0].values.map((a, z) => (
                      <React.Fragment key={z}>
                        <th className="text-center">
                          {a.year.endsWith('/0') || a.year.includes('/0 vs')
                            ? a.year.replaceAll('/0', '')
                            : a.year}
                          {text && year === a.year && (
                            <i
                              className="fa fa-info-circle"
                              id="tooltip"
                              style={{
                                marginLeft: '3px',
                              }}
                            >
                              <div className="custom-tooltip">
                                <UncontrolledTooltip
                                  placement="top"
                                  target="tooltip"
                                  style={{
                                    backgroundColor: '#505d69',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'start',
                                  }}
                                >
                                  <span>{text}</span>
                                  <span>
                                    {difference ? `Fark: ${difference}` : ''}
                                  </span>
                                </UncontrolledTooltip>
                              </div>
                            </i>
                          )}
                        </th>
                      </React.Fragment>
                    ))}
                </tr>
              </thead>
              <tbody>
                {row.dataList &&
                  row.dataList[0] &&
                  row.dataList[0].titles &&
                  row.dataList.map((b, i) => (
                    <React.Fragment key={i}>
                      {b.mainEntry && (
                        <tr>
                          <td
                            colSpan={12}
                            style={{
                              background: '#a4b5c0',
                            }}
                          >
                            <p
                              style={{
                                textAlign: 'center',
                                padding: 0,
                                margin: 10,
                                fontWeight: 'bold',
                              }}
                            >
                              {b.mainEntry}
                            </p>
                          </td>
                        </tr>
                      )}

                      {filterTitles(b.titles).map((a, j) => (
                        <React.Fragment key={j}>
                          <tr key={j}>
                            <td
                              key={j}
                              colSpan={
                                a.values[0]?.value !== 0 ||
                                a.values[1]?.value !== 0 ||
                                a.values[2]?.value !== 0 ||
                                a.values[3]?.value !== 0
                                  ? 1
                                  : 12
                              }
                            >
                              <p>{a.title}</p>
                            </td>

                            {(a.values[0]?.value !== 0 ||
                              a.values[1]?.value !== 0 ||
                              a.values[2]?.value !== 0 ||
                              a.values[3]?.value !== 0) &&
                              a.values.map((c, index) => (
                                <React.Fragment key={index}>
                                  <td className="text-center">
                                    {c.year.toString().indexOf('vs') === -1 &&
                                    c.year.toString().indexOf('Dikey') === -1 &&
                                    c.year.toString().indexOf('Common') ===
                                      -1 ? (
                                      c.value === 0 ? (
                                        '0'
                                      ) : (
                                        <FormattedNumber value={c.value} />
                                      )
                                    ) : c.value === 0 ? (
                                      '0'
                                    ) : (
                                      <FormattedNumber
                                        value={c.value}
                                        style="percent"
                                      />
                                    )}
                                  </td>
                                </React.Fragment>
                              ))}
                          </tr>
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
              </tbody>
            </Table>
          </Tab>
        ))}
      </Tabs>
    </div>
  );
}
