// @ts-nocheck
import React, { useState } from 'react';
import { Card, CardBody, Table, Button, Input, Label } from 'reactstrap';
import { BootstrapTable, Column, IdColumn } from 'react-admin-base-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Breadcrumb } from 'react-admin-base-nazox';
import { useFetch } from 'react-admin-base';
import { Alerts } from '../../Components/Alerts';
import { useAuth } from 'react-admin-base';
import { showSwal } from '../../Components/CustomForm';
import { useNavigate, Link } from 'react-router-dom';
import Template from '../../FileTemplatesAndImages/Template.xlsx';
import { SingleFilePicker } from 'react-admin-base-bootstrap';
import { Loading } from '../../Components/Loading';

//CONTROLLED UNCONTROLLED ERROR FIX IT

const params = { sort: 'id', desc: true };

export const ConsolidationTable = () => {
  const intl = useIntl();
  const [modal, setModal] = useState({});
  const [done, setDone] = useState(false);
  const [api] = useAuth();
  const [loading, setLoading] = useState(false);
  const [, setError] = useState('');
  const [customers, customersLoading] = useFetch('/customer/get/all/groups');
  const [ID, setID] = useState('');
  const [file, setFile] = useState({});
  const navigate = useNavigate();
  const onSubmit = async (row) => {
    setLoading(true);
    try {
      const result = await api.tokenized.post('/consolidation', {
        inGroupExcelFileId: file ? file[row.id].id : 0,
        customerIds: [...modal[row.id]],
        sectorCode: row.sector.code,
      });

      if (result.status === 200) {
        setID(result.data.requestModel);
        setDone(true);
      }
    } catch (e) {
      if (e.message && e.response.data.message) {
        const errorMessage = e.response.data.message;
        showSwal(
          `<p>${
            typeof errorMessage === 'string'
              ? ''
              : localStorage.frayjet_frayjet_language === 'en'
                ? errorMessage.eng
                : errorMessage.tr
          }</p>`,
          setError,
          intl
        );
      }
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };
  const toggle = (i) => {
    if (modal[i]) {
      const clone = modal;
      delete clone[i];
      setModal({ ...clone });
    } else setModal((prev) => ({ ...prev, [i]: [] }));
  };
  if (done) {
    setTimeout(() => {
      document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setModal({});
      navigate('/analysis/' + window.btoa(ID) + '/edit');
      setDone(false);
    }, 2000);
  }

  const handleFilePicker = (row, e) => {
    if (e) setFile({ [row.id]: e });
    else setFile({});
  };

  return (
    <Breadcrumb
      data={[
        {
          href: '/consolidation',
          name: <FormattedMessage id="CONSOLIDATION" />,
        },
      ]}
    >
      {loading && <Loading />}
      {done && (
        <Alerts
          className="fa fa-check"
          color="success"
          messageId={'COMPLETED_CONSOLIDATION'}
        />
      )}

      <Card>
        <CardBody>
          {!customersLoading ? (
            <>
              <div style={{ display: 'flex', justifyContent: 'right' }}>
                <Label>
                  Grup İçi Alış/Satış ve Borç/Alacak şablonunu{' '}
                  <Link
                    to={Template}
                    target="_blank"
                    download="Grup İçi Alış Satış ve Borç Alacak Şablonu"
                  >
                    <u>buradan</u>{' '}
                  </Link>
                  indirebilirsiniz.
                </Label>
              </div>

              <BootstrapTable
                noStrip
                url="/customer/get/paginate/groups"
                defaultParams={params}
              >
                <thead>
                  <tr>
                    <IdColumn />
                    <Column sort="name">
                      <FormattedMessage id={'name'} />
                    </Column>
                    <Column sort="email">
                      <FormattedMessage id={'ADD_USER_EMAIL'} />{' '}
                    </Column>
                    <Column sort="customers">
                      <FormattedMessage id={'CUSTOMERS'} />{' '}
                    </Column>
                    <Column>
                      <i className="fas fa-water"></i>
                    </Column>
                  </tr>
                </thead>
                <tbody>
                  {(row, j) => {
                    const customersData = customers.filter(
                      (d) => d.groupId === row.id
                    );
                    return (
                      row.isGroupCompany && (
                        <React.Fragment key={j}>
                          <tr key={row.id}>
                            <td className="min text-center">{row.id}</td>
                            <td>{row.name}</td>
                            <td>{row.email}</td>
                            <td className="min">
                              <Button
                                outline={true}
                                color="primary"
                                onClick={() => {
                                  toggle(row.id);
                                }}
                              >
                                <i className="fas fa-eye" />
                              </Button>
                            </td>
                            <td className="min">
                              <Button
                                type="submit"
                                color="primary"
                                size="sm"
                                disabled={
                                  !modal[row.id] || modal[row.id].length < 1
                                }
                                onClick={() => {
                                  if (!file[row.id]) {
                                    showSwal(
                                      ` <p>Lütfen Grup İçi Alış/Satış ve Borç/Alacak Şablonunu Bilgisayarınıza 
        <a href="${Template}" target="_blank" download="Grup İçi Alış Satış ve Borç Alacak Şablonu">
          <u>indirip</u>
        </a>, Doldurduktan Sonra Yükleyiniz.
      </p>`,
                                      '',
                                      intl
                                    );
                                  } else onSubmit(row);
                                }}
                              >
                                <FormattedMessage id="CONSOLIDATION_BUTTON" />
                              </Button>{' '}
                            </td>
                          </tr>

                          <td colSpan="6">
                            <div
                              style={{ display: !modal[row.id] && 'none' }}
                              className={'modalContentEnable'}
                            >
                              <div
                                style={{
                                  marginBottom: '1em',
                                  display: 'flex',
                                  justifyContent: 'right',
                                }}
                              >
                                <Label style={{ marginRight: '1em' }}>
                                  Grup İçi Alış/Satış ve Borç/Alacak Şablonu
                                  Yükle:
                                </Label>
                                <SingleFilePicker
                                  accepts=".pdf,.xlsx,.png,.jpg"
                                  value={file[row.id]}
                                  onChange={(e) => handleFilePicker(row, e)}
                                  disabled={
                                    !file[row.id] && Object.keys(file).length
                                  }
                                />
                              </div>
                              {customersData.length > 0 ? (
                                <Table striped bordered hover size="sm">
                                  <thead>
                                    <tr>
                                      <IdColumn />
                                      <th>
                                        <FormattedMessage id={'name'} />
                                      </th>
                                      <th>
                                        <FormattedMessage
                                          id={'STATEMENT_YEAR'}
                                        />{' '}
                                      </th>
                                      <th>
                                        <FormattedMessage id="companyAnalysisType" />
                                      </th>{' '}
                                      <th>
                                        <i className="fas fa-water"></i>
                                      </th>{' '}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {customersData.map((c, i) => {
                                      return (
                                        <tr key={i}>
                                          <td>{c.id}</td>
                                          <td
                                            style={{
                                              display: 'flex',
                                            }}
                                          >
                                            {c.name}{' '}
                                            <a
                                              href={
                                                '/customer/' + c.id + '/edit'
                                              }
                                            >
                                              {' '}
                                              <button
                                                className="btn btn-outline-primary"
                                                style={{
                                                  width: '10px',
                                                  height: '10px',
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                                  alignItems: 'center',
                                                  marginLeft: '5px',
                                                  border: 0,
                                                }}
                                              >
                                                <i className="fa fa-pencil-alt"></i>
                                              </button>
                                            </a>
                                          </td>
                                          <td>
                                            {c.lastAnalyzeYear}/
                                            {c.lastAnalyzeMonth}
                                          </td>
                                          <td style={{ display: 'flex' }}>
                                            {c.analyzeType
                                              ? intl.formatMessage({
                                                  id: c.analyzeType,
                                                })
                                              : ''}
                                            {c.lastAnalyzeId && (
                                              <Link
                                                to={
                                                  '/analysis/' +
                                                  window.btoa(c.lastAnalyzeId) +
                                                  '/edit'
                                                }
                                                target="_blank"
                                              >
                                                <Button
                                                  outline
                                                  color="secondary"
                                                  style={{
                                                    width: '10px',
                                                    height: '10px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    marginLeft: '5px',
                                                    border: 0,
                                                  }}
                                                >
                                                  <i className="fa fa-pencil-alt" />{' '}
                                                </Button>
                                              </Link>
                                            )}
                                          </td>

                                          <td>
                                            <Input
                                              type="checkbox"
                                              checked={
                                                modal[row.id]
                                                  ? modal[row.id].includes(c.id)
                                                  : false
                                              }
                                              disabled={
                                                Object.keys(modal).length > 1
                                              }
                                              onChange={(e) => {
                                                const checked =
                                                  e.target.checked;

                                                if (checked)
                                                  setModal((prev) => ({
                                                    [row.id]: [
                                                      ...(prev[row.id] || []),
                                                      c.id,
                                                    ],
                                                  }));
                                                else {
                                                  setModal((prev) => ({
                                                    [row.id]: prev[
                                                      row.id
                                                    ].filter((e) => e !== c.id),
                                                  }));
                                                }
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </Table>
                              ) : (
                                intl.formatMessage({
                                  id: 'NO_CUSTOMER',
                                })
                              )}
                            </div>
                          </td>
                        </React.Fragment>
                      )
                    );
                  }}
                </tbody>
              </BootstrapTable>
            </>
          ) : (
            <Alerts
              color="primary"
              className="fas fa-spin fa-spinner"
              messageId="LOADING"
              label=""
            />
          )}
        </CardBody>
      </Card>
    </Breadcrumb>
  );
};

export default ConsolidationTable;
