import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button_ } from '../../Components/ActionButtons/ActionButtons';
import { icons } from '../../Icons/icons';
import { Modal, ModalHeader, ModalBody, Table } from 'reactstrap';
import { DownloadFileButton } from '../../Components/DownloadFileButton';

export const AllAnalysisButton = (props) => {
  const intl = useIntl();
  const { analysisDocuments } = props;
  const [open, setOpen] = React.useState(false);
  const toggle = () => setOpen(!open);

  return (
    <>
      <Button_
        text={intl.formatMessage({
          id: 'UPLOADED_FINANCIALS',
        })}
        color="primary"
        iconClassName={icons.eye}
        onClick={toggle}
      />
      <AnalysisModal
        open={open}
        toggle={toggle}
        analysisDocuments={analysisDocuments}
        intl={intl}
      />
    </>
  );
};

const AnalysisModal = ({ open, toggle, analysisDocuments, intl }) => {
  return (
    <Modal isOpen={open} toggle={toggle} size="xl">
      <ModalHeader toggle={toggle} />
      <ModalBody>
        <Table>
          <thead>
            <tr>
              <th>
                {' '}
                <FormattedMessage id="FINANCIAL_NAME" />
              </th>
              <th>
                {' '}
                <FormattedMessage id="FINANCIAL_DATE" />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {analysisDocuments.map((analysisDocument) => (
              <tr key={analysisDocument.id}>
                <td>{analysisDocument.documentName}</td>
                <td>
                  {analysisDocument.year}/{analysisDocument.month}
                </td>
                <td>
                  <DownloadFileButton
                    messageId={intl.formatMessage({
                      id: 'DOWNLOAD',
                    })}
                    url={`file/download?documentId=${analysisDocument.documentId}&fileName=${analysisDocument.documentName}`}
                    dname={analysisDocument.documentName}
                    className={'btn btn-info btn btn-secondary'}
                    style={{ whiteSpace: 'nowrap' }}
                    method="get"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ModalBody>
    </Modal>
  );
};
