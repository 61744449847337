import React from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import {
  ActionsColumn,
  BootstrapDataTable,
  Column,
} from 'react-admin-base-bootstrap';
import { useUser } from '../../UserProvider';
import { useFetch } from 'react-admin-base';
import { useIntl } from 'react-intl';
import { providerTypeNums } from '../../../Util/Constants';
const params = { sort: 'id', desc: true, itemPerPage: 5 };

const CustomerAnalysisTable2 = ({ id }) => {
  const user = useUser();
  const [company] = useFetch('/company/get/user/' + user.id);
  const intl = useIntl();

  React.useEffect(() => {
    const tableHeader = document.getElementsByClassName('card-header');
    if (tableHeader.length > 0) {
      tableHeader[0].style.display = 'none';
    }
  }, []);

  return (
    <BootstrapDataTable
      noStrip
      url={`/analyze/get/customer/${id}`}
      defaultParams={params}
    >
      <thead>
        <tr>
          <Column sort="customer.statementYear">
            <FormattedMessage id="PREV_ANL_DECLARATION_YEAR_TITLE" />
          </Column>
          <Column sort="customer.isConsolidated">
            <FormattedMessage id="GROUP/SOLO" />
          </Column>
          <Column sort="createdAt">
            <FormattedMessage id="PREV_ANL_ANALYSIS_DATE" />
          </Column>
          <Column sort="providerType">
            <FormattedMessage id="companyAnalysisType" />
          </Column>
          <ActionsColumn />
        </tr>
      </thead>
      <tbody>
        {(row) => {
          return (
            <tr key={row.id}>
              <td>
                {row.customer
                  ? `${row.customer.statementYear}/${
                      row.customer.statementMonth || ''
                    }`
                  : ''}
              </td>
              <td>
                {row.isConsolidated ? (
                  <FormattedMessage id="GROUP" />
                ) : (
                  <FormattedMessage id="SOLO" />
                )}
              </td>
              <td>
                <FormattedDate value={row.createdAt} />
              </td>
              <td>
                {providerTypeNums.includes(row.providerType)
                  ? intl.formatMessage({
                      id: row.providerType,
                    })
                  : ''}{' '}
              </td>
              <td className="min">
                <Link
                  to={'/report/' + window.btoa(row.documentId) + '/summary'}
                >
                  <Button outline={true} color="primary">
                    <i className="fas fa-eye" />{' '}
                  </Button>
                </Link>{' '}
                {company &&
                  (company.code === 'ta3meed' ||
                    company.code === 'aktifbank' ||
                    company.code === 'ta3meedbnpl' ||
                    company.code === 'param' ||
                    company.code === 'tofas' ||
                    company.code === 'teb') && (
                    <Link
                      to={
                        company.code !== 'ta3meed'
                          ? '/analysis/' + window.btoa(row.documentId) + '/edit'
                          : '/analysis/' +
                            window.btoa(row.documentId) +
                            '/edit/empty'
                      }
                    >
                      <Button outline={true} color="secondary">
                        <i className="fa fa-pencil-alt" />{' '}
                      </Button>
                    </Link>
                  )}
              </td>
            </tr>
          );
        }}
      </tbody>
    </BootstrapDataTable>
  );
};

export const CustomerAnalysisTable = React.memo(CustomerAnalysisTable2);
