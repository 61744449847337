import React from 'react';

import useFetch from '../../../Hooks/useFetch';
import { useUser } from '../../../Components/UserProvider';
import { Breadcrumb } from 'react-admin-base-nazox';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Button, Card, CardBody } from 'reactstrap';
import { Alerts } from '../../../Components/Alerts';
import { useIntl } from 'react-intl';
import { LinkButton } from '../../../Components/ActionButtons/ActionButtons';
import { icons } from '../../../Icons/icons';
import { providerTypeNums } from '../../../Util/Constants';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputField } from '../../../Components/Input';
import { useAuth } from 'react-admin-base';
import { DownloadFileButton } from '../../../Components/DownloadFileButton';

const canSeeEditButton = (company) => {
  return (
    company &&
    (company.code === 'ta3meed' ||
      company.code === 'aktifbank' ||
      company.code === 'ta3meedbnpl' ||
      company.code === 'param' ||
      company.code === 'worqcompany' ||
      company.code === 'tofas' ||
      company.code === 'teb' ||
      company.code === 'usulventures' ||
      company.code === 'tbaydin' ||
      company.code === 'tai')
  );
};

const summaryPageUrl = (code, documentId) => {
  const url =
    code !== 'ta3meed'
      ? '/analysis/' + window.btoa(documentId) + '/edit'
      : '/analysis/' + window.btoa(documentId) + '/edit/empty';

  return url;
};

export const PreviousAnalysisTable = () => {
  const user = useUser();
  const intl = useIntl();
  const [api] = useAuth();

  const [company, loadingCompany] = useFetch(`/company/get/user/${user.id}`);

  const isTofas = company?.code === 'tofas';
  const companyId = user?.companyId;
  const buttonText = `${intl.formatMessage({
    id: 'COMPLETED_ANALYZES_TITLE',
  })} ${intl.formatMessage({ id: 'DOWNLOAD' })}`;
  const excelName = intl.formatMessage({ id: 'ANALYSES' });
  const [lazyParams, setLazyParams] = React.useState({
    first: 1,
    rows: 10,
    page: 1,
    sortField: 'id',
    sortOrder: 1,
    filters: {},
    multiSortMeta: [],
  });

  const [globalFilterValue, setGlobalFilterValue] = React.useState('');

  const [loading, setLoading] = React.useState(false);
  const [analysis, setAnalysis] = React.useState(null);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;

    setGlobalFilterValue(value);
  };

  const onPage = (event) => {
    setLazyParams({ ...event, page: event.page + 1 });
  };

  const onFilter = (event) => {
    console.log('event', event);
    setLazyParams(event);
  };

  const onSort = (event) => {
    setLazyParams((prev) => ({
      ...event,
      page: 1,
    }));
  };

  const totalRecords = analysis ? analysis.itemCount : 0;
  const footer = (
    <span
      style={{
        position: 'absolute',
        bottom: '20px',
        right: '5px',
      }}
    >
      <FormattedMessage id="FILTERED_ANALYSIS_NUMBER" />: {totalRecords}
    </span>
  );

  const fetchData = async () => {
    setLoading(true);

    try {
      const response = await api.tokenized.get(
        `/analyze/get?page=${lazyParams.page}&itemPerPage=${lazyParams.rows}&query=${globalFilterValue}&sort=${lazyParams.sortField}&desc=${lazyParams.sortOrder === 1 ? true : false}`
      );
      setAnalysis(response.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  const renderHeader = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <InputField
          name="globalFilterValue"
          type="text"
          onChange={onGlobalFilterChange}
          value={globalFilterValue}
          placeHolder={intl.formatMessage({ id: 'SEARCH' })}
          style={{ width: '15%' }}
        />
        <div
          style={{
            display: 'flex',
            gap: '2px',
          }}
        >
          {isTofas && (
            <DownloadFileButton
              messageId={buttonText}
              url={`report/detailedAnalysisReport/excelFile/${companyId}`}
              dname={excelName}
              className={'btn btn-info btn btn-secondary'}
              method="get"
              style={{
                padding: '0.25rem 0.5rem',
              }}
            />
          )}
          {company?.code !== 'ta3meedbnpl' && (
            <LinkButton
              url={'/analysis/create'}
              text={intl.formatMessage({
                id: 'NEW_ANALYSIS_TITLE',
              })}
              size="sm"
              color="success"
              iconClassName={icons.startAnalysis}
            />
          )}
        </div>
      </div>
    );
  };
  React.useEffect(() => {
    fetchData();
  }, [lazyParams, globalFilterValue]);

  if (loadingCompany)
    return (
      <Alerts
        color="primary"
        className="fas fa-spin fa-spinner"
        messageId={'LOADING'}
        label=""
      />
    );
  return (
    <DataTable
      value={analysis ? [...analysis.data] : []}
      className="mt-3"
      scrollable
      loading={loading}
      paginator
      rowsPerPageOptions={[5, 10, 25, 50]}
      onPage={onPage}
      first={lazyParams.first}
      rows={lazyParams.rows}
      totalRecords={totalRecords}
      onSort={onSort}
      sortField={lazyParams.sortField}
      sortOrder={lazyParams.sortOrder}
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport"
      currentPageReportTemplate=" "
      emptyMessage={intl.formatMessage({ id: 'NO_DATA_IS_AVAILABLE' })}
      lazy
      header={renderHeader}
      onGlobalFilterChange={onFilter}
      footer={footer}
    >
      <Column
        field="fullName"
        header={<FormattedMessage id="analysisCustomerId" />}
        sortField="customer.fullName"
        sortable
        body={(rowData) => {
          return rowData.customer ? (
            rowData.customer.fullName
          ) : (
            <FormattedMessage id="COMPANY_NOTFOUND" />
          );
        }}
      />
      <Column
        field="statementYear"
        header={<FormattedMessage id="PREV_ANL_DECLARATION_YEAR_TITLE" />}
        sortField="customer.statementYear"
        sortable
        body={(rowData) => {
          return rowData.customer
            ? `${rowData.customer.statementYear}/${
                rowData.customer.statementMonth || ''
              }`
            : '';
        }}
      />
      <Column
        field="isConsolidated"
        header={<FormattedMessage id="GROUP/SOLO" />}
        sortField="isConsolidated"
        sortable
        body={(rowData) => {
          return rowData.isConsolidated ? (
            <FormattedMessage id="GROUP" />
          ) : (
            <FormattedMessage id="SOLO" />
          );
        }}
      />
      <Column
        field="createdAt"
        header={<FormattedMessage id="PREV_ANL_ANALYSIS_DATE" />}
        sortField="createdAt"
        sortable
        body={(rowData) => <FormattedDate value={rowData.createdAt} />}
      />

      <Column
        header={<FormattedMessage id="companyAnalysisType" />}
        body={(rowData) => {
          return rowData.providerType &&
            providerTypeNums.includes(rowData.providerType) ? (
            <span>{<FormattedMessage id={rowData.providerType} />}</span>
          ) : (
            ''
          );
        }}
        sortField="providerType"
        field="providerType"
        sortable
      />

      <Column
        header={<i className="fas fa-water" />}
        body={(rowData) => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '0.5rem',
              }}
            >
              <LinkButton
                url={'/report/' + window.btoa(rowData.documentId) + '/summary'}
                text={intl.formatMessage({
                  id: 'TO_ANALYSIS',
                })}
                size="sm"
                color="primary"
                iconClassName={icons.startAnalysis}
              />
              {canSeeEditButton(company) && (
                <LinkButton
                  url={summaryPageUrl(company.code, rowData.documentId)}
                  text={intl.formatMessage({
                    id: 'EDIT',
                  })}
                  size="sm"
                  color="secondary"
                  iconClassName={icons.edit}
                />
              )}
            </div>
          );
        }}
        style={{
          width: '10%',
        }}
      />
    </DataTable>
  );
};
